import React from "react";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";
import { Link } from "react-router-dom";


export default function Career() {
    return (
        <>
            <IndexNavbar fixed />
            <section className="header relative justify-center items-center "  >
            </section>
            <section className="container mx-auto md:pt-32 pt-24">
                <h1 className="md:text-4xl text-xl let text-darkgreen-100 font-semibold text-center md:pt-2 px-6">
                    We're more than just a Workplace. <br></br>Here, you can be you to achieve your best.
                </h1>
                <h1 className="text-base let text-black text-center md:pt-4 pt-6 md:px-40 px-2">
                    The Agri Processing industry in India currently works in an archaic way and there is a huge opportunity
                    to contribute in organizing the industry. We are building technologies that simplify the industry and have
                    a real impact on the stakeholders involved.
                </h1>
                <h1 className="text-base let text-black text-center md:pt-4 pt-6 md:px-40">
                    Agrizy as an organization believes in ownership and accountability. The individuals and teams have a
                    fantastic chance to own the charters right from conceptualization till execution and being able to
                    witness the impact.
                </h1>
            </section>

            <section className="container mx-auto md:px-28 px-2">
                <div className="w-full lg:w-12/12 md:w-12/12 py-6 md:px-22 md:py-12">
                    <div className="flex web ">
                        <div className="lg:w-12/12 md:w-12/12  border border-4 md:px-8 px-2 py-4 rounded-lg md:py-8 border-green-400 shadow-lg">
                            <div className="flex flex-wrap justify-center">
                                <div className="lg:w-6/12 md:w-6/12">
                                    <h1 className="text-left font-bold let text-darkgreen-100 text-base">
                                        Job Title:
                                        <span className="font-normal let text-black text-base px-2">
                                            Node.js Developer
                                        </span>
                                    </h1>

                                </div>
                                <div className="lg:w-6/12 md:w-6/12">
                                    <h1 className="text-right font-bold let text-darkgreen-100 text-base">
                                        Location:
                                        <span className="font-normal let text-black text-base px-2">
                                            Bangalore
                                        </span>
                                    </h1>

                                </div>
                            </div>
                            <h1 className="text-left font-bold let text-darkgreen-100 text-base pt-6">
                                Roles and Responsibilities:
                            </h1>
                            <h1 className="text-base pt-1 text-left let ">
                                Are you an aspiring Node.js Developer looking for an opportunity to work in a great team
                                and advance your skills and career?
                                <h1 className="text-base pt-1 text-left let ">
                                    If you are an analytical problem solver with a strong Node.js knowledge and a passion for
                                    teamwork and innovation, we have the perfect job for you!
                                </h1>
                                <h1 className="text-base pt-1 text-left let ">
                                    We are looking for a Node.js Developer to join our fun, energetic team who are running
                                    at full speed to ignite a digital disruption in telecommunications. You will be collaborating
                                    with our front-end application developers, designing back-end components, and integrating data
                                    storage and protection solutions.
                                </h1>

                                {/* <h1 className="text-left font-bold let text-darkgreen-100 text-base pt-6">
                                    Description:
                                </h1>

                                <ul class="md:py-2 md:px-2 let list-none">
                                    <li className="py-1">-  Develop the back-end software, maintain and update the existing code.</li>
                                    <li className="py-1">- Designing customer-facing UI and back-end services for various business processes.</li>
                                    <li className="py-1">- Running diagnostic tests, repairing defects, and providing technical support.</li>
                                    <li className="py-1">- Keeping informed of advancements in the field of Node.js development.</li>

                                </ul> */}

                                <h1 className="text-left font-bold let text-darkgreen-100 text-base pt-4">
                                    Technical Skills & Experience:
                                </h1>
                                <ul class="md:py-2 md:px-2 let list-none">
                                    <li className="py-1">-  Bachelor Degree in Computer Science, Computer Engineering or similar.</li>
                                    <li className="py-1">- 4+ years of programming experience.</li>
                                    <li className="py-1">- In depth knowledge of Node.js.</li>
                                    <li className="py-1">- Experience with REST, WebSocket, MySQL/PostgreSQL, MongoDB, Redis.</li>
                                    <li className="py-1">-  Experience with Docker and AWS Infrastructure.</li>
                                    <li className="py-1">- Understanding of Asynchronous Development and Data Security Principles.</li>
                                    <li className="py-1">- Ability to write readable, well-documented code.</li>
                                    <li className="py-1">- Self-motivation, goal-oriented approach.</li>
                                </ul>
                                <div className=" md:pt-4">
                                    <Link to="/form">
                                        <button className="justify-left text-sm rounded text-sm border-transparent bg-green-100 text-white font-bold hover:bg-purple-500 py-2 px-2">
                                            Apply Now
                                        </button>
                                    </Link>
                                </div>
                            </h1>
                        </div>
                    </div>
                </div>
            </section>


            <section className="container mx-auto md:px-28 px-2">
                <div className="w-full lg:w-12/12 md:w-12/12 md:px-4 md:px-22 md:pb-26 pb-12">
                    <div className="flex web ">
                        <div className="lg:w-12/12 md:w-12/12  border border-4 md:px-8 px-2 py-4 rounded-lg md:py-8 border-green-400 shadow-lg">
                            <div className="flex flex-wrap justify-center">
                                <div className="lg:w-6/12 md:w-6/12">
                                    <h1 className="text-left font-bold let text-darkgreen-100 text-base">
                                        Job Title:
                                        <span className="font-normal let text-black text-base px-2">
                                            Treasury Analyst
                                        </span>
                                    </h1>

                                </div>
                                <div className="lg:w-6/12 md:w-6/12">
                                    <h1 className="md:text-right text-left font-bold let text-darkgreen-100 text-base">
                                        Location:
                                        <span className="font-normal let text-black text-base px-2">
                                            Bangalore
                                        </span>
                                    </h1>
                                </div>
                            </div>

                            <h1 className="text-base pt-1 text-left let ">

                                <h1 className="text-left font-bold let text-darkgreen-100 text-base pt-4">
                                    Responsibilities:
                                </h1>
                                <ul class="md:py-2 md:px-2 let list-none">
                                    <li className="py-1">- Liaisoning with Financial Institutions (FIs) for post sanction activities of off-BS loans /
                                    Escrow account operations</li>
                                    <li className="py-1">- Coordinating for Invoice Discounting including:
                                        <ul class="md:py-2 md:px-2 let list-none">
                                            <li className="py-1">- Sharing of invoices with the FIs for discounting</li>
                                            <li className="py-1">- Interest calculation and interest reversal in case of early repayment</li>
                                            <li className="py-1">- Tracking of payment due date and tracking of payment from customer to FI or tracking of payment from Bizcovery to FI</li>
                                        </ul>
                                    </li>
                                    <li className="py-1">- Planning cash flow on a daily basis and tracking the same at EOD vis-à-vis estimated
cash flow</li>
                                    <li className="py-1">- Cash flow planning and ensuring placement of surplus of cash flow</li>
                                    <li className="py-1">- Timely discounting of invoices and maximum utilization of limits</li>
                                    <li className="py-1">-  Timely closure and utilization of SCF facilities</li>

                                </ul>


                                <h1 className="text-left font-bold let text-darkgreen-100 text-base pt-4">
                                    Technical Skills & Experience:
                                </h1>
                                <ul class="md:py-2 md:px-2 let list-none">

                                    <li className="py-1">- Proficient in MS Excel</li>
                                    <li className="py-1">- Overview of Credit terms</li>
                                    <li className="py-1">- Calculation of interest, cash flow understanding</li>
                                    <li className="py-1">- Should be aware about basic interest calculations</li>
                                </ul>

                                <div className=" md:pt-6">
                                    <Link to="/form">
                                        <button className="justify-left text-sm rounded text-sm border-transparent bg-green-100 text-white font-bold hover:bg-purple-500 py-2 px-2">
                                            Apply Now
                                        </button>
                                    </Link>
                                </div>
                            </h1>
                        </div>
                    </div>
                </div>
            </section>


            <section className="container mx-auto md:px-28 px-2">
                <div className="w-full lg:w-12/12 md:w-12/12 md:px-4 md:px-22 md:pb-26 pb-12">
                    <div className="flex web ">
                        <div className="lg:w-12/12 md:w-12/12  border border-4 md:px-8 px-2 py-4 rounded-lg md:py-8 border-green-400 shadow-lg">
                            <div className="flex flex-wrap justify-center">
                                <div className="lg:w-6/12 md:w-6/12">
                                    <h1 className="text-left font-bold let text-darkgreen-100 text-base">
                                        Job Title:
                                        <span className="font-normal let text-black text-base px-2">
                                            Credit Analyst
                                        </span>
                                    </h1>

                                </div>
                                <div className="lg:w-6/12 md:w-6/12">
                                    <h1 className="md:text-right text-left font-bold let text-darkgreen-100 text-base">
                                        Location:
                                        <span className="font-normal let text-black text-base px-2">
                                            Bangalore
                                        </span>
                                    </h1>
                                </div>
                            </div>

                            <h1 className="text-base pt-1 text-left let ">

                                <h1 className="text-left font-bold let text-darkgreen-100 text-base pt-4">
                                    Responsibilities:
                                </h1>
                                <ul class="md:py-2 md:px-2 let list-none">
                                    <li className="py-1">- Evaluating corporate clients from credit risk perspective. Corporates can be large,
medium, small.</li>
                                    <li className="py-1">- Analysing credit worthiness of buyers by understanding business model, financial
performance, industry risk, market feedback.</li>
                                    <li className="py-1">- Understanding of industry risks pertaining to various agricultural commodities.</li>
                                    <li className="py-1">- Reviewing credit clients on a half-yearly basis account conduct, other material
developments.</li>
                                    <li className="py-1">-  Clearly highlighting various risks involved and basis mitigants available, whether to
go ahead or not.</li>

                                </ul>


                                <h1 className="text-left font-bold let text-darkgreen-100 text-base pt-4">
                                    Technical Skills & Experience:
                                </h1>
                                <ul class="md:py-2 md:px-2 let list-none">
                                    <li className="py-1">- Financial Ratios</li>
                                    <li className="py-1">- Calculation of interest</li>
                                    <li className="py-1">- Familiarity with corporate rating system</li>
                                    <li className="py-1">- Corporate Credit Underwriting Knowledge</li>
                                    <li className="py-1">- Good with Excel</li>
                                </ul>

                                <div className=" md:pt-6">
                                    <Link to="/form">
                                        <button className="justify-left text-sm rounded text-sm border-transparent bg-green-100 text-white font-bold hover:bg-purple-500 py-2 px-2">
                                            Apply Now
                                        </button>
                                    </Link>
                                </div>
                            </h1>
                        </div>
                    </div>
                </div>
            </section>


            <section className="container mx-auto md:px-28 px-2">
                <div className="w-full lg:w-12/12 md:w-12/12 md:px-4 md:px-22 md:pb-26 pb-12">
                    <div className="flex web ">
                        <div className="lg:w-12/12 md:w-12/12  border border-4 md:px-8 px-2 py-4 rounded-lg md:py-8 border-green-400 shadow-lg">
                            <div className="flex flex-wrap justify-center">
                                <div className="lg:w-6/12 md:w-6/12">
                                    <h1 className="text-left font-bold let text-darkgreen-100 text-base">
                                        Job Title:
                                        <span className="font-normal let text-black text-base px-2">
                                            Associate Manager - Finance
                                        </span>
                                    </h1>

                                </div>
                                <div className="lg:w-6/12 md:w-6/12">
                                    <h1 className="md:text-right text-left font-bold let text-darkgreen-100 text-base">
                                        Location:
                                        <span className="font-normal let text-black text-base px-2">
                                            Bangalore
                                        </span>
                                    </h1>
                                </div>
                            </div>

                            <h1 className="text-base pt-1 text-left let ">

                                <h1 className="text-left font-bold let text-darkgreen-100 text-base pt-4">
                                    Responsibilities:
                                </h1>
                                <ul class="md:py-2 md:px-2 let list-none">
                                    <li className="py-1">- Prepare financial reports for comprehensive analysis.</li>
                                    <li className="py-1">- Generate Management Information System (MIS) reports.</li>
                                    <li className="py-1">- Conduct book closures efficiently.</li>
                                    <li className="py-1">- Expertise in both direct and indirect taxation.</li>
                                    <li className="py-1">- Ensure compliance with relevant regulations and requirements.
medium, small.</li>
                                    <li className="py-1">- Successful candidates will demonstrate a strong work ethic, effective communication skills, and the ability to collaborate with diverse teams to achieve organizational goals.</li>

                                </ul>


                                <h1 className="text-left font-bold let text-darkgreen-100 text-base pt-4">
                                    Technical Skills & Experience:
                                </h1>
                                <ul class="md:py-2 md:px-2 let list-none">

                                    <li className="py-1">- We are currently seeking the expertise of a proficient Chartered Accountant
to join our team.</li>
                                    <li className="py-1">- Good understanding of preparing MIS reports in excel.</li>
                                    <li className="py-1">- Expertise in deep understanding of all financial products.</li>
                                    <li className="py-1">- Analysis of Balance Sheets and Financial Data of Companies / Issuers.</li>
                                    <li className="py-1">- Proficiency in MS Excel Eg- Vlookup, Pivot table and various excel functions.</li>
                                    <li className="py-1">- Experience in maintaining books of accounts and balance sheets</li>
                                    <li className="py-1">- Experience in Reconciliations.</li>
                                </ul>

                                <div className=" md:pt-6">
                                    <Link to="/form">
                                        <button className="justify-left text-sm rounded text-sm border-transparent bg-green-100 text-white font-bold hover:bg-purple-500 py-2 px-2">
                                            Apply Now
                                        </button>
                                    </Link>
                                </div>
                            </h1>
                        </div>
                    </div>
                </div>
            </section>


            <section className="container mx-auto md:px-28 px-2">
                <div className="w-full lg:w-12/12 md:w-12/12 md:px-4 md:px-22 md:pb-26 pb-12">
                    <div className="flex web ">
                        <div className="lg:w-12/12 md:w-12/12  border border-4 md:px-8 px-2 py-4 rounded-lg md:py-8 border-green-400 shadow-lg">
                            <div className="flex flex-wrap justify-center">
                                <div className="lg:w-6/12 md:w-6/12">
                                    <h1 className="text-left font-bold let text-darkgreen-100 text-base">
                                        Job Title:
                                        <span className="font-normal let text-black text-base px-2">
                                            Finance Executive Payments
                                        </span>
                                    </h1>

                                </div>
                                <div className="lg:w-6/12 md:w-6/12">
                                    <h1 className="md:text-right text-left font-bold let text-darkgreen-100 text-base">
                                        Location:
                                        <span className="font-normal let text-black text-base px-2">
                                            Bangalore
                                        </span>
                                    </h1>
                                </div>
                            </div>

                            <h1 className="text-base pt-1 text-left let ">

                                <h1 className="text-left font-bold let text-darkgreen-100 text-base pt-4">
                                    Responsibilities:
                                </h1>
                                <ul class="md:py-2 md:px-2 let list-none">
                                    <li className="py-1">- Manage payment transactions efficiently.</li>
                                    <li className="py-1">- Generate debit and credit notes as required.</li>
                                    <li className="py-1">- Perform reconciliation tasks.</li>
                                    <li className="py-1">- Create and issue invoices promptly.</li>
                                    <li className="py-1">- Handle end-to-end processing of payables, receivables, and payroll.</li>
                                    <li className="py-1">- Generate monthly management reports accurately.</li>
                                    <li className="py-1">- Oversee comprehensive bookkeeping tasks from start to finish.</li>
                                    <li className="py-1">- Successful candidates will demonstrate a strong work ethic, effective communication skills, and the ability to collaborate with diverse teams to achieve organizational goals.</li>
                                </ul>


                                <h1 className="text-left font-bold let text-darkgreen-100 text-base pt-4">
                                    Technical Skills & Experience:
                                </h1>
                                <ul class="md:py-2 md:px-2 let list-none">

                                    <li className="py-1">- Proficiency in MS Excel Eg- Vlookup, Pivot table and various excel functions</li>
                                    <li className="py-1">- Working experience of Tally</li>
                                    <li className="py-1">- Basic knowledge of TDS, GST & ROC COMPLIANCES & FILLINGS</li>
                                    <li className="py-1">- Must have experience in Bookkeeping & Accounting</li>
                                    <li className="py-1">- Must be good at Written and Verbal Communication</li>
                                </ul>

                                <div className=" md:pt-6">
                                    <Link to="/form">
                                        <button className="justify-left text-sm rounded text-sm border-transparent bg-green-100 text-white font-bold hover:bg-purple-500 py-2 px-2">
                                            Apply Now
                                        </button>
                                    </Link>
                                </div>
                            </h1>
                        </div>
                    </div>
                </div>
            </section>



















            <Footer />
        </>
    );
}
