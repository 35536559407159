import React, { useState } from "react";
import emailjs from "emailjs-com";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";
import aboutbg1 from "assets/img/aboutbg1.jpg";
import { height } from "tailwindcss/defaultTheme";
import herbalbackground from "assets/img/herbalbackground.png";



export default function Contact() {


    const [firstName, setFirstName] = useState('')
    const [email, setEmail] = useState('')
    const [tel, setTel] = useState('')
    const [message, setMessage] = useState('')
    const [nameError, setNameError] = useState(false)
    const [emailError, setEmailError] = useState(false)
    const [emailregexError, setEmailRegexError] = useState(false)
    const [telError, setTelError] = useState(false)
    const [telregexError, setTelRegexError] = useState(false)
    const [messageError, setMessageError] = useState(false)



    const handleSubmit = (e) => {
        e.preventDefault();

        if (firstName.length === 0 || email.length == 0 || (tel.length === 0) || message.length === 0) {
            setNameError(true);
            setEmailError(true);
            setTelError(true);
            setMessageError(true);

        }


        else if (!(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email))) {
            setEmailRegexError(true);
        }

        else if (!(/^[6-9]\d{9}$/.test(tel))) {
            setTelRegexError(true);
        }

        else {
            console.log("First Name: ", firstName);
            console.log("Email: ", email);
            console.log("Tel: ", tel);
            console.log("Message: ", message);

            emailjs.sendForm('service_00wzcfy', 'template_ddo9uha', e.target, 'JOQyh_ZagnFjbOxse')
                .then((result) => {
                    console.log(result.text);
                }, (error) => {
                    console.log(error.text);
                });

            alert("Form Submitted Successfully")
        }
    }


    return (
        <>
            <IndexNavbar fixed />
            <section className="container mx-auto md:pt-24 pt-24">
                <h1 className="md:text-4xl text-3xl font-bold md:pt-16 pt-12 text-center text-white">
                    ENGAGE WITH
                    <span className="md:text-4xl font-extrabold text-center text-green-100 px-2">
                        AGRIZY
                    </span>
                </h1>
            </section>

            <div className="md:pt-0 pt-12">
                <div className=" bg-no-repeat md:px-16 md:py-16  let heigt ">
                    <section className="container mx-auto">
                        <div className="w-full lg:w-12/12 md:w-12/12">
                            <div className="flex flex-wrap tabsection">
                                <div className="lg:w-7/12 md:w-7/12 px-6 py-10">
                                    <div className="tabsection md:py-16 py-10 md:px-16">
                                        <h1 className="md:text-xl text-center text-xl md:px-0 px-4 font-bold pb-6 ">
                                            Drop us your Email ID or Phone Number to get started.
                                        </h1>
                                        <form onSubmit={handleSubmit} className="tabsection py-6 px-6">
                                            <div className="relative w-full py-2 ">

                                                <input type="text"
                                                    className="border-1 mt-2 px-3 py-3 bg-white text-blueGray-600 placeholder-blueGray-300 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150 contactsample"
                                                    placeholder="Name" name="name" maxlength="25" onChange={e => setFirstName(e.target.value)}
                                                />
                                                {nameError && firstName.length === 0 ?
                                                    <label className="error">Enter Your Name</label> : ""}
                                            </div>

                                            <div className="relative w-full py-2">
                                                <input type="text"
                                                    className="border-1 mt-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 contactsample"
                                                    placeholder="Email" name="email" onChange={e => setEmail(e.target.value) + setEmailRegexError(e.target.value)}
                                                />
                                                {emailError && email.length === 0 ?
                                                    <label className="error">Enter Your Email</label> : ""}
                                                {emailregexError && (!(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email))) ?
                                                    <label className="error">Please Enter Valid Email</label> : ""}



                                                <div className="relative w-full py-2">
                                                    <input
                                                        className="border mt-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 contactsample"
                                                        placeholder="Contact Number" name="tel" maxlength="10" onChange={e => setTel(e.target.value) + setTelRegexError(e.target.value)}
                                                    />
                                                    {telError && tel.length === 0 ?
                                                        <label className="error pt-2">Enter Your Contact Number</label> : ""}
                                                    {telregexError && (!(/^[6-9]\d{9}$/.test(tel))) ?
                                                        <label className="error pt-2">Please Enter Valid Number</label> : ""}
                                                </div>

                                                <input type="checkbox" id="vehicle1" name="values" value="I like to buy products from Agrizy" />
                                                <label for="vehicle1" className="px-2">  I like to buy products from Agrizy</label><br></br>
                                                <input type="checkbox" id="vehicle2" name="values" value=" I like to sell my products to Agrizy" />
                                                <label for="vehicle2" className="px-2">  I like to sell my products to Agrizy</label><br></br>
                                                <input type="checkbox" id="vehicle3" name="values" value="Other Interests" />
                                                <label for="vehicle3" className="px-2">Other Interests</label><br></br>

                                                <div className="relative w-full ">
                                                    <textarea
                                                        className="border-1 mt-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 contactsample"
                                                        placeholder="Message" name="message" onChange={e => setMessage(e.target.value)}
                                                    />
                                                    {messageError && message.length === 0 ?
                                                        <label className="error">Please Enter Your Message</label> : ""}
                                                </div>
                                            </div>

                                            <div className="text-right mt-6">
                                                <button
                                                    className="tabsection  text-green-100  text-md font-extrabold uppercase px-2 py-3 rounded px-6 hover:shadow-lg"
                                                    type="submit"
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>


                                <div className="lg:w-4/12 md:w-4/12 web mx-auto">
                                    <div className="md:flex md:flex-wrap md:py-10 py-10 md:px-0 px-12">
                                        <div className="lg:w-4/12 md:4/12 pt-12 ">
                                            <div className="iconnss1 pointer web">
                                                <a href="tel:+91 84313 18616">
                                                    <img
                                                        alt="..."
                                                        className=" font-extrabold p-2  "
                                                        src={require("assets/img/Skype1.png").default} /></a></div></div>
                                        <div className="lg:w-6/12 md:6/12 md:text-left text-center md:pt-12">
                                            <h1 className="text-2xl md:px-0 px-6 font-semibold md:pt-2 pt-4">
                                                Call Us
                                            </h1>
                                            <p className="text-base md:px-0 px-6 pt-2">
                                                +91 84313 18616
                                            </p>
                                        </div></div>


                                    <div className="md:flex md:flex-wrap md:py-10 md:px-0 px-12">
                                        <div className="lg:w-4/12 md:4/12">
                                            <div className="iconnss1 pointer web">
                                                <a href="mailto:hello@agrizy.in">
                                                    <img
                                                        alt="..."
                                                        className=" font-extrabold w-16 p-2 pt-2 px-2 "
                                                        src={require("assets/img/mail1.png").default} /></a></div></div>
                                        <div className="lg:w-6/12 md:6/12 md:text-left text-center">
                                            <h1 className="text-2xl font-semibold md:pt-2 pt-2 md:px-0 px-6">
                                                Email Us
                                            </h1>
                                            <p className="text-base pt-2 md:px-0 px-6">
                                                hello@agrizy.in
                                            </p>
                                        </div></div>


                                    <div className="md:flex md:flex-wrap md:py-10 py-10 ">
                                        <div className="lg:w-4/12 md:4/12 md:px-0 px-12">
                                            <div className="iconnss1 pointer web">
                                                <img
                                                    alt="..."
                                                    className=" font-extrabold w-16 p-2 pt-2 px-2 "
                                                    src={require("assets/img/home1.png").default} /></div></div>
                                        <div className="lg:w-6/12 md:6/12 text-center md:text-left md:px-0 px-12">
                                            <h1 className="text-2xl font-semibold md:pt-2 pt-2">
                                                Address
                                            </h1>
                                            <h4 className="font-semibold">
                                                Bizcovery Private Limited
                                            </h4>
                                            <p className="text-base ">
                                                3rd Floor of Krishna Complex, BDA Site No. 2339, 17th Cross, 27th Main Road, Sector 1, HSR Layout, Bangalore, Karnataka 560102
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>

            <ul class="slideshow ex">
                <li><span></span></li>
                <li><span>2</span></li>
                <li><span></span></li>
                <li><span></span></li>
                <li><span></span></li>
            </ul>



            <section>
                <div class="responsive-map">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3889.0600522584095!2d77.64678471473955!3d12.903860090900338!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae15edfeffe8ef%3A0xfad537b1c2771892!2sAgrizy%20(Bizcovery%20Pvt%20Ltd)!5e0!3m2!1sen!2sin!4v1673434170158!5m2!1sen!2sin" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </section>

            {/* <section className="container fff mx-auto  ">
                <div className="w-full md:w-12/12 lg:w-12/12 xl:w-12/12 md:py-16">
                    <div className="justify-center flex flex-wrap flex-wrap-reverse px-2">

                        <div className="w-full md:w-12/12 lg:w-12/12 border rounded shadow-2xl bg-white md:py-10">
                            <div className="flex flex-wrap flex-wrap-reverse justify-center">

                                {/* E-commerce*/}
            {/*<div className="md:w-4/12 lg:w-4/12 ">


                                    <h6 className="text-xl  pt-6 ttt font-extrabold pb-2 text-left mt-4">
                                        Address
                                    </h6>

                                    <p className="text-base text-black font-normal text-left">
                                        <b>Agrizy</b>,<br></br>
                                        Suite A-3-9, 8 Avenue<br></br>  middle street 8/1,
                                        bangalore
                                    </p>

                                    <h6 className=" text-xl ttt font-extrabold py-2 text-left">
                                        Email
                                    </h6>
                                    <p className="text-base text-black font-normal text-left">
                                        info@agrizy.com

                                    </p>

                                    <h6 className=" text-xl ttt font-extrabold py-2 text-left">
                                        Call Us
                                    </h6>
                                    <p className="text-base text-black font-normal text-left">
                                        +91123456987

                                    </p></div>*/}

            {/*<div className="w-full flex flex-wrap lg:w-6/12 md:w-6/12 xl:w-6/12 py-2">
                                        <div className="lg:w-4/12 md:w-4/12 xl:w-4/12 ">
                                            <img className="w-8"
                                                src={require("assets/img/tel.png").default} />

                                        </div>
                                        <div className="lg:w-8/12 md:w-8/12 xl:w-8/12 pt-2 v2">
                                            <span>
                                                +91 1234569870
                                            </span>
                                        </div>
                                    </div>

                                    <div className="w-full flex flex-wrap lg:w-6/12 md:w-6/12 border xl:w-6/12">
                                        <div className="lg:w-4/12 md:w-4/12 xl:w-4/12 ">
                                            <img className="w-8"
                                                src={require("assets/img/loco.png").default} />

                                        </div>
                                        <div className="lg:w-8/12 md:w-8/12 xl:w-8/12 pt-2 v2">

                                            Pride Hotel Lane, Narveer

                                        </div>
                                    </div>

                                    <div className="w-full flex flex-wrap lg:w-6/12 md:w-6/12 xl:w-6/12 py-2">
                                        <div className="lg:w-4/12 md:w-4/12 xl:w-4/12">
                                            <img className="w-8"
                                                src={require("assets/img/mail.png").default} />

                                        </div>
                                        <div className="lg:w-8/12 md:w-8/12 xl:w-8/12 pt-2 v2">
                                            <span>
                                                +91 1234569870
                                            </span>
                                        </div>
    </div>*/}

            {/*     <div className="mt-4 lg:mb-0 mb-6 py-6">
                                        <button
                                            className="bg-white text-green shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                                            type="button"
                                        >
                                            <i className="fab fa-instagram"></i>
                                        </button>
                                        <button
                                            className="bg-white text-blueGray-800 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                                            type="button"
                                        >
                                            <i className="fab fa-facebook-square"></i>
                                        </button>
                                        <button
                                            className="bg-white text-green shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                                            type="button"
                                        >
                                            <i className="fab fa-linkedin"></i>
                                        </button>
                                        <button
                                            className="bg-white text-blueGray-800 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                                            type="button"
                                        >
                                            <i className="fab fa-twitter"></i>
                                        </button>
                                    </div>

                                </div>


                                <div className="w-10/12 md:w-6/12 sm:pt-28 lg:w-6/12 lg:pl-22   sm:pb-98" >


                                    <div className="flex-auto px-2 lg:px-10 py-10 mt-4 pt-0">


                                        <form onSubmit={handleSubmit}>
                                            <div className="relative w-full py-2 ">

                                                <input type="text"
                                                    className="border-1 mt-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    placeholder="Name" name="name" onChange={e => setFirstName(e.target.value)}
                                                />
                                                {nameError && firstName.length === 0 ?
                                                    <label className="error">Enter Your Name</label> : ""}
                                            </div>

                                            <div className="relative w-full py-2">
                                                <input type="text"
                                                    className="border-1 mt-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    placeholder="Email" name="email" onChange={e => setEmail(e.target.value) + setEmailRegexError(e.target.value)}
                                                />
                                                {emailError && email.length === 0 ?
                                                    <label className="error">Enter Your Email</label> : ""}
                                                {emailregexError && (!(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email))) ?
                                                    <label className="error">Please Enter Valid Email</label> : ""}



                                                <div className="relative w-full py-2">
                                                    <input
                                                        className="border mt-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                        placeholder="Contact Number" name="tel" onChange={e => setTel(e.target.value) + setTelRegexError(e.target.value)}
                                                    />
                                                    {telError && tel.length === 0 ?
                                                        <label className="error pt-2">Enter Your Contact Number</label> : ""}
                                                    {telregexError && (!(/^[6-9]\d{9}$/.test(tel))) ?
                                                        <label className="error pt-2">Please Enter Valid Number</label> : ""}


                                                </div>

                                                <div className="relative w-full ">
                                                    <textarea
                                                        className="border-1 mt-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                        placeholder="Message" name="message" onChange={e => setMessage(e.target.value)}
                                                    />
                                                    {messageError && message.length === 0 ?
                                                        <label className="error">Please Enter Your Message</label> : ""}
                                                </div>
                                            </div>

                                            <div className="text-center mt-6">
                                                <button
                                                    className="bg-green text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                                                    type="submit"
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                                                    </section>*/}






            {/* <section className="container mx-auto">
                <div className="w-full md:w-12/12 lg:w-12/12 xl:w-12/12">
                    <div className="py-12 text-center">
                        <div className="text-4xl font-bold">
                            Get in
                            <span className="text-green font-bold text-4xl"> Touch</span>
                        </div>
                    </div>
                    <p className="text-base text-center mt-4 font-normal">
                        ITR Filing for Business, Proprietorship, Salaried & Self Employed Individuals
                    </p>

                    <div className=" py-6 mx-auto flex flex-wrap md:w-6/12 lg:w-6/12 xl:w-6/12">
                        <div className="web md:w-4/12 lg:w-4/12 xl:w-4/12">
                            <img className="w-4/12"
                                src={require("assets/img/periscope.png").default} />
                            <h1 className="font-semibold mt-2 text-sm">

                                Petaling Jaya, Selangor Darul Ehsan, Malaysia.
                            </h1>
                        </div>
                        <div className="web md:w-4/12 lg:w-4/12 xl:w-4/12">
                            <img className="w-16"
                                src={require("assets/img/neumorphism.png").default} />
                            <h1 className="font-semibold mt-2 text-sm">
                                +91 1234569010, <br></br>+63 1234569010
                            </h1>
                        </div>
                        <div className="web md:w-4/12 lg:w-4/12 xl:w-4/12">
                            <img className="w-16"
                                src={require("assets/img/neumorphism2.png").default} />
                            <h1 className="font-semibold mt-2 text-sm">
                                info@agrizy.com<br></br>
                                info@agrizy.com
                            </h1>
                        </div>
                    </div>

                    <form onSubmit={handleSubmit}>
                        <div className="relative w-6/12 mx-auto py-2 ">

                            <input type="text"
                                className="border-1 mt-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                placeholder="Name" name="name" onChange={e => setFirstName(e.target.value)}
                            />
                            {nameError && firstName.length === 0 ?
                                <label className="error">Enter Your Name</label> : ""}
                        </div>

                        <div className="relative w-6/12 mx-auto  py-2">
                            <input type="text"
                                className="border-1 mt-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                placeholder="Email" name="email" onChange={e => setEmail(e.target.value) + setEmailRegexError(e.target.value)}
                            />
                            {emailError && email.length === 0 ?
                                <label className="error">Enter Your Email</label> : ""}
                            {emailregexError && (!(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email))) ?
                                <label className="error">Please Enter Valid Email</label> : ""}



                            <div className="relative w-full py-2">
                                <input
                                    className="border mt-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    placeholder="Contact Number" name="tel" onChange={e => setTel(e.target.value) + setTelRegexError(e.target.value)}
                                />
                                {telError && tel.length === 0 ?
                                    <label className="error">Enter Your Contact Number</label> : ""}
                                {telregexError && (!(/^[6-9]\d{9}$/.test(tel))) ?
                                    <label className="error">Please Enter Valid Number</label> : ""}


                            </div>

                            <div className="relative w-full ">
                                <textarea
                                    className="border-1 mt-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    placeholder="Message" name="message" onChange={e => setMessage(e.target.value)}
                                />
                                {messageError && message.length === 0 ?
                                    <label className="error">Please Enter Your Message</label> : ""}
                            </div>
                        </div>

                        <div className="text-center w-6/12 mx-auto  mt-6">
                            <button
                                className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                                type="submit"
                            >
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
                
                                </section>*/}

            <Footer />
        </>
    );
}


