import React, { useState } from "react";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";

export default function Privacy() {
    return (
        <>
            <IndexNavbar fixed />
            <section className="  bg-no-repeat header relative justify-center items-center md:pt-24 pt-32" >
            </section>
            <div className="container mx-auto let heigt md:pb-10 md:px-16 px-4">
                <h1 className="text-black font-bold md:text-4xl text-2xl text-center md:pt-16">
                    Privacy Policy
                </h1>
                <h1 className="text-green-100 font-semibold md:text-2xl text-xl  md:pt-12 pt-6">
                    Your privacy is critically important to us (Agrizy)
                </h1>
                <p className="text-sm md:pt-4 pt-4">
                    Welcome to Bizcovery Private Limited.
                </p>
                <p className="text-sm md:pt-4 pt-4">
                    Corporate Office Address:<br></br>
                    Bizcovery Private Limited (Agrizy)<br></br>
                    3rd Floor of Krishna Complex,<br></br>
                    BDA Site No. 2339, 17th, Cross, 27th Main Road,<br></br>
                    Sector 1, HSR Layout,<br></br>
                    Bengaluru, Karnataka 560102<br></br>
                    Contact: +91 84313 18616
                </p>
                <p className="text-sm md:pt-4 pt-4">
                    It is Agrizy's policy to respect your privacy regarding any information we may
                    collect while operating our website. This Privacy Policy applies to <a className="font-bold text-green-100" href="https://agrizy.in/">
                        agrizy.in
                    </a>  (hereinafter, "us", "we", or <a className="font-bold text-green-100" href="https://agrizy.in/">
                        agrizy.in
                    </a> ). We respect your privacy and are
                    committed to protecting personally identifiable information you may provide us through the Website.
                    We have adopted this Privacy Policy to explain what information may be collected
                    on our Website, how we use this information, and under what circumstances we may disclose the
                    information to third parties. This Privacy Policy applies only to information we collect through
                    the Website and does not apply to our collection of information from other sources.
                </p>
                <p className="text-sm md:pt-4 pt-4">
                    This Privacy Policy, together with the Terms and conditions posted on our Website,
                    set forth the general rules and policies governing your use of our Website. Depending
                    on your activities when visiting our Website, you may be required to agree to additional terms and conditions.
                </p>
                <h1 className="text-green-100 font-semibold md:text-2xl text-xl  md:pt-8 pt-6">
                    Website Visitors
                </h1>
                <p className="text-sm md:pt-4 pt-4">
                    Like most website operators, Agrizy collects non-personally-identifying information of the
                    sort that web browsers and servers typically make available, such as the browser type, language
                    preference, referring site, and the date and time of each visitor request. Agrizy's purpose in
                    collecting non-personally identifying information is to better understand how Agrizy's visitors
                    use its website. From time to time, Agrizy may release non-personally-identifying information in
                    the aggregate, e.g., by publishing a report on trends in the usage of its website.
                </p>
                <p className="text-sm md:pt-4 pt-4">
                    Agrizy also collects potentially personally-identifying information like Internet Protocol
                    (IP) addresses for logged in users and for users leaving comments on <a className="font-bold text-green-100" href="https://agrizy.in/">
                        agrizy.in
                    </a>    blog posts. Agrizy only discloses logged in user and commenter IP addresses under the same circumstances
                    that it uses and discloses personally-identifying information as described below.
                </p>
                <h1 className="text-green-100 font-semibold md:text-2xl text-xl  md:pt-8 pt-6">
                    Sign Up For our services
                </h1>
                <p className="text-sm md:pt-4 pt-4">
                    If you sign up for our services, we will ask you for your name and phone number.
                    The purpose of the processing is to allow you access our services and to provide
                    the services to you. The purpose is additionally to reach you with important notifications
                    and updates regarding the services.
                </p>
                <h1 className="text-green-100 font-semibold md:text-2xl text-xl md:pt-8 pt-6">
                    Gathering Of Personally-Identifying Information
                </h1>
                <p className="text-sm md:pt-4 pt-4">
                    Certain visitors to Agrizy's websites choose to interact with Agrizy in ways that require Agrizy to
                    gather personally-identifying information. The amount and type of information that Agrizy gathers
                    depends on the nature of the interaction. For example, we ask visitors who sign up for a blog at   <a className="font-bold text-green-100" href="https://agrizy.in/">
                        agrizy.in</a>    to provide a user name, email address and phone number.
                </p>
                <h1 className="text-green-100 font-semibold md:text-2xl text-xl  md:pt-8">
                    Security
                </h1>
                <p className="text-sm md:pt-4 pt-4">
                    The security of your Personal Information is important to us, but remember that no method of
                    transmission over the Internet, or method of electronic storage is 100% secure. While we strive
                    to use commercially acceptable means to protect your Personal Information, we cannot guarantee
                    its absolute security.
                </p>
                <h1 className="text-green-100 font-semibold md:text-2xl text-xl  md:pt-8 pt-6">
                    Advertisements
                </h1>
                <p className="text-sm md:pt-4 pt-4">
                    Ads appearing on our website may be delivered to users by advertising partners, who may set cookies.
                    These cookies allow the ad server to recognize your computer each time they send you an online advertisement
                    to compile information about you or others who use your computer. This information allows ad networks to,
                    among other things, deliver targeted advertisements that they believe will be of most interest to you.
                    This Privacy Policy covers the use of cookies by Agrizy and does not cover the use of cookies by any advertisers.
                </p>
                <h1 className="text-green-100 font-semibold md:text-2xl text-xl  md:pt-8 pt-6">
                    Links To External Sites
                </h1>
                <p className="text-sm md:pt-4 pt-4">
                    Our services may contain links to external sites that are not operated by us. If you click on a third party link,
                    you will be directed to that third party's site.
                    We strongly advise you to review the Privacy Policy and terms and conditions of every site you visit.
                </p>
                <p className="text-sm md:pt-4 pt-4">
                    We have no control over, and assume no responsibility for the content, privacy policies or practices of any third party sites, products or services.
                </p>
                <h1 className="text-green-100 font-semibold md:text-2xl text-xl  md:pt-8 pt-6">
                    <a className="font-bold text-green-100" href="https://agrizy.in/">
                        agrizy.in
                    </a>  Uses Google AdWords For Remarketing
                </h1>
                <p className="text-sm md:pt-4 pt-4">
                    <a className="font-bold text-green-100" href="https://agrizy.in/">
                        agrizy.in
                    </a>   uses the remarketing service to advertise on third party websites (including Google)
                    to previous visitors to our site. It could mean that we advertise to previous visitors who haven't completed
                    a task on our site, for example using the contact form to make an enquiry. This could be in the form of an
                    advertisement on the Google search results page, or a site in the Google Display Network. Third-party vendors,
                    including Google, use cookies to serve ads based on someone's past visits. Of course, any data collected will
                    be used in accordance with our own privacy policy and Google's privacy policy.
                </p>
                <p className="text-sm md:pt-4 pt-4">
                    You can set preferences for how Google advertises to use the Google Ad Preferences page,
                    and if you want you can opt out of interest-based advertising entirely by cookie settings or
                    permanently using a browser plugin.
                </p>
                <h1 className="text-green-100 font-semibold md:text-2xl text-xl pt-6 md:pt-8">
                    Protection Of Certain Personally-Identifying Information
                </h1>
                <p className="text-sm md:pt-4 pt-4">
                    Agrizy discloses potentially personally-identifying and personally-identifying information only to those of its
                    employees, contractors and affiliated organizations that (i) need to know that information in order to process
                    it on Agrizy's behalf or to provide services available at Agrizy's website, and (ii) that have agreed not to
                    disclose it to others. Some of those employees, contractors and affiliated organizations may be located
                    outside of your home country by using Agrizy's website, you consent to the transfer of such information
                    to them. Agrizy will not rent or sell potentially personally-identifying and personally-identifying information
                    to anyone. Other than to its employees, contractors and affiliated organizations, as described above, Agrizy
                    discloses potentially personally-identifying and personally-identifying information only in response to a
                    subpoena, court order or other governmental request, or when Agrizy believes in good faith that disclosure
                    is reasonably necessary to protect the property or rights of Agrizy, third parties or the public at large.
                </p>
                <p className="text-sm md:pt-4 pt-4">
                    If you are a registered user of   <a className="font-bold text-green-100" href="https://agrizy.in/">
                        agrizy.in
                    </a>   and have supplied your email address,
                    Agrizy may occasionally send you an email to tell you about new features, solicit your feedback,
                    or just keep you up to date with what's going on with Agrizy and our products. We primarily use our blog to
                    communicate this type of information, so we expect to keep this type of email to a minimum. If you send us a
                    request (for example via a support email or via one of our feedback mechanisms), we reserve the right to publish
                    it in order to help us clarify or respond to your request or to help us support other users. Agrizy takes all
                    measures reasonably necessary to protect against the unauthorized access, use, alteration or destruction of
                    potentially personally-identifying and personally-identifying information.
                </p>
                <h1 className="text-green-100 font-semibold md:text-2xl text-xl md:pt-8 pt-6">
                    Aggregated Statistics
                </h1>
                <p className="text-sm md:pt-4 pt-4 md:pb-10 pb-6">
                    Agrizy may collect statistics about the behavior of visitors to its website. Agrizy may display this
                    information publicly or provide it to others. However, Agrizy does not disclose your personally-identifying
                    information.
                </p>
                <h1 className="text-green-100 font-semibold md:text-2xl text-xl  ">
                    Cookies
                </h1>
                <p className="text-sm md:pt-4 pt-4 ">
                    To enrich and perfect your online experience, Agrizy uses "Cookies", similar technologies and services provided by
                    others to display personalized content, appropriate advertising and store your preferences on your computer.
                </p>
                <p className="text-sm md:pt-4 pt-4 ">
                    A cookie is a string of information that a website stores on a visitor's computer, and that the visitor's browser
                    provides to the website each time the visitor returns. Agrizy uses cookies to help Agrizy identify and track visitors,
                    their usage of   <a className="font-bold text-green-100" href="https://agrizy.in/">
                        agrizy.in
                    </a>   , and their website access preferences. Agrizy visitors who do not wish
                    to have cookies placed on their computers should set their browsers to refuse cookies before using Agrizy's website,
                    with the drawback that certain features of Agrizy's websites may not function properly without the aid of cookies.
                </p>
                <p className="text-sm md:pt-4 pt-4 md:pb-10 pb-6">
                    By continuing to navigate our website without changing your cookie settings, you hereby acknowledge and agree to Agrizy's use of cookies.
                </p>
            </div>
            <Footer />
        </>
    );
}