import React, { useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";

export default function Press() {
    const settings = {
        slidesToShow: 4,
        slidesToScroll: 1,
        centerMode: false,
        arrows: true,
        dots: true,
        speed: 500,
        infinite: true,
        autoplaySpeed: 9000,
        autoplay: true,
        className: "center",
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    centerMode: false, /* set centerMode to false to show complete slide instead of 3 */
                    slidesToScroll: 1
                }
            },


            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    centerMode: false,
                    dots: false,
                }
            },

            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: false,
                    dots: false,
                }
            }
        ]

    };

    return (
        <>
            <IndexNavbar fixed />
            <section className="container mx-auto md:pb-8 md:pt-24 pt-24">
                <div className="md:py-10">
                    <h1 className=" text-4xl pt-2 let pb-6 text-darkgreen-100 text-center fff font-bold">
                        MEDIA COVERAGE
                    </h1>
                </div>
                {/* 1st row */}
                <div className="w-full lg:w-12/12 md:w-12/12">
                    <div className="flex flex-wrap">
                        <div className="lg:w-6/12 md:6/12">
                            <div className="flex flex-wrap">
                                <div className="lg:w-6/12 md:6/12 md:pb-0 pb-6">
                                    <div className="md:px-4">
                                        <div className="w-full lg:w-12/12 md:w-12/12 pointer md:pb-10 rounded-2xl md:px-0 px-6">
                                            <a href="https://www.vccircle.com/ankur-capital-omnivore-others-invest-in-bengaluru-based-agritech-firm-agrizy" target="blank">
                                                <div className="bg-gray-100">
                                                    <div className="px-4 py-2 web">
                                                        <img className="w-full h-64 rounded-lg"
                                                            src={require("assets/img/article1.png").default}
                                                            alt="...">
                                                        </img>
                                                        <h1 className="font-bold let text-green-100 text-base  md:pt-4">
                                                            VCCircle
                                                        </h1>
                                                        <h1 className=" let text-black text-xs  md:pt-2">
                                                            Ankur Capital, Omnivore, Others Invest In Bengaluru-Based AgriTech Firm Agrizy
                                                        </h1>
                                                        <div className="md:pt-4 md:pb-2">
                                                            <a href="https://www.vccircle.com/ankur-capital-omnivore-others-invest-in-bengaluru-based-AgriTech-firm-agrizy" target="blank">
                                                                <button className="text-sm rounded text-sm border-transparent bg-green-100 text-white font-bold hover:bg-purple-500 py-2 px-6">
                                                                    Read
                                                                </button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div className="lg:w-6/12 md:6/12 md:pb-0 pb-6">
                                    <div className="md:px-4">
                                        <div className="w-full lg:w-12/12 md:w-12/12 pointer md:pb-10 rounded-2xl md:px-0 px-6">
                                            <a href="https://bwdisrupt.businessworld.in/article/AgTech-Firm-Agrizy-Bags-4-Million-Seed-Funding-Led-By-Ankur-Capital/04-04-2022-424444/" target="blank">
                                                <div className="bg-gray-100">
                                                    <div className="px-4 py-2 web">
                                                        <img className="w-full h-64 rounded-lg"
                                                            src={require("assets/img/article2.png").default}
                                                            alt="...">
                                                        </img>
                                                        <h1 className="font-bold let text-green-100 text-base  md:pt-4">
                                                            Business World
                                                        </h1>
                                                        <h1 className=" let text-black text-xs  md:pt-2">
                                                            AgriTech Firm Agrizy Bags $4 Million Seed Funding Led By Ankur Capital
                                                        </h1>
                                                        <div className="md:pt-4 md:pb-2">
                                                            <a href="http://bwdisrupt.businessworld.in/article/AgTech-Firm-Agrizy-Bags-4-Million-Seed-Funding-Led-By-Ankur-Capital/04-04-2022-424444/" target="blank">
                                                                <button className="text-sm rounded text-sm border-transparent bg-green-100 text-white font-bold hover:bg-purple-500 py-2 px-6">
                                                                    Read
                                                                </button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="lg:w-6/12 md:6/12">
                            <div className="flex flex-wrap">
                                <div className="lg:w-6/12 md:6/12 md:pb-0 pb-6">
                                    <div className="md:px-4">
                                        <div className="w-full lg:w-12/12 md:w-12/12 pointer md:pb-10 rounded-2xl md:px-0 px-6">
                                            <a href="https://entrackr.com/2022/04/AgriTech-startup-agrizy-raises-4-mn-seed-round/" target="blank">
                                                <div className="bg-gray-100">
                                                    <div className="px-4 py-2 web">
                                                        <img className="w-full h-64 rounded-lg"
                                                            src={require("assets/img/article3.png").default}
                                                            alt="...">
                                                        </img>
                                                        <h1 className="font-semibold let text-green-100 text-base  md:pt-4">
                                                            ENTRACKR
                                                        </h1>
                                                        <h1 className=" let text-black text-xs  md:pt-2">
                                                            AgriTech Startup Agrizy Raises <br></br>$4 Million Seed Round
                                                        </h1>
                                                        <div className="md:pt-4 md:pb-2">
                                                            <a href="https://entrackr.com/2022/04/AgriTech-startup-agrizy-raises-4-mn-seed-round/" target="blank">
                                                                <button className="text-sm rounded text-sm border-transparent bg-green-100 text-white font-bold hover:bg-purple-500 py-2 px-6">
                                                                    Read
                                                                </button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div className="lg:w-6/12 md:6/12 md:pb-0 pb-6">
                                    <div className="md:px-4">
                                        <div className="w-full lg:w-12/12 md:w-12/12 pointer md:pb-10 rounded-2xl md:px-0 px-6">
                                            <a href="https://inc42.com/buzz/agrizy-raises-4-mn-seed-funding-to-develop-digital-solutions-for-agri-processors/?itm_source=inc42-sponsored-content&itm_medium=website&itm_campaign=sponsored-content" target="blank">
                                                <div className="bg-gray-100">
                                                    <div className="px-4 py-2 web">
                                                        <img className="w-full h-64 rounded-lg"
                                                            src={require("assets/img/article4.png").default}
                                                            alt="...">
                                                        </img>
                                                        <h1 className="font-semibold let text-green-100 text-base  md:pt-4">
                                                            INC42
                                                        </h1>
                                                        <h1 className=" let text-black text-xs  md:pt-2">
                                                            Agrizy Raises $4 Million Seed Funding To Develop Digital Solutions For Agri Processors
                                                        </h1>
                                                        <div className="md:pt-4 md:pb-2">
                                                            <a href="https://inc42.com/buzz/agrizy-raises-4-mn-seed-funding-to-develop-digital-solutions-for-agri-processors/?itm_source=inc42-sponsored-content&itm_medium=website&itm_campaign=sponsored-content" target="blank">
                                                                <button className="text-sm rounded  border-transparent bg-green-100 text-white font-bold hover:bg-purple-500 py-2 px-6">
                                                                    Read
                                                                </button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/* 2nd row */}
                <div className="w-full lg:w-12/12 md:w-12/12">
                    <div className="flex flex-wrap">
                        <div className="lg:w-6/12 md:6/12">
                            <div className="flex flex-wrap">
                                <div className="lg:w-6/12 md:6/12 md:pb-0 pb-6">
                                    <div className="md:px-4">
                                        <div className="w-full lg:w-12/12 md:w-12/12 pointer md:pb-10 rounded-2xl md:px-0 px-6">
                                            <a href="https://tradebrains.in/features/biz-agrizy/" target="blank">
                                                <div className="bg-gray-100">
                                                    <div className="px-4 py-2 web">
                                                        <img className="w-full h-64 rounded-lg"
                                                            src={require("assets/img/article5.png").default}
                                                            alt="...">
                                                        </img>
                                                        <h1 className="font-semibold let text-green-100 text-base  md:pt-4">
                                                            Trade Brains
                                                        </h1>
                                                        <h1 className=" let text-black text-xs  md:pt-2">
                                                            AgriTech startup Agrizy Raises USD $4 Million From Investors Including Ankur Capital
                                                        </h1>
                                                        <div className="md:pt-4 md:pb-2">
                                                            <a href="https://tradebrains.in/features/biz-agrizy/" target="blank">
                                                                <button className="text-sm rounded text-sm border-transparent bg-green-100 text-white font-bold hover:bg-purple-500 py-2 px-6">
                                                                    Read
                                                                </button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div className="lg:w-6/12 md:6/12 md:pb-0 pb-6">
                                    <div className="md:px-4">
                                        <div className="w-full lg:w-12/12 md:w-12/12 pointer md:pb-10 rounded-2xl md:px-0 px-6">
                                            <a href="https://www.devdiscourse.com/article/business/1989599-AgriTech-startup-agrizy-raises-usd-4-mn-from-investors-including-ankur-capital" target="blank">
                                                <div className="bg-gray-100">
                                                    <div className="px-4 py-2 web">
                                                        <img className="w-full h-64 rounded-lg"
                                                            src={require("assets/img/article6.png").default}
                                                            alt="...">
                                                        </img>
                                                        <h1 className="font-semibold let text-green-100 text-base  md:pt-4">
                                                            Devdiscourse
                                                        </h1>
                                                        <h1 className=" let text-darkgreen-100 text-xs  md:pt-2">
                                                            AgriTech Startup Agrizy Raises USD $4 Million From Investors Including Ankur Capital
                                                        </h1>
                                                        <div className="md:pt-4 md:pb-2">
                                                            <a href="https://www.devdiscourse.com/article/business/1989599-AgriTech-startup-agrizy-raises-usd-4-mn-from-investors-including-ankur-capital" target="blank">
                                                                <button className="text-sm rounded text-sm border-transparent bg-green-100 text-white font-bold hover:bg-purple-500 py-2 px-6">
                                                                    Read
                                                                </button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="lg:w-6/12 md:6/12">
                            <div className="flex flex-wrap">
                                <div className="lg:w-6/12 md:6/12 md:pb-0 pb-6">
                                    <div className="md:px-4">
                                        <div className="w-full lg:w-12/12 md:w-12/12 pointer md:pb-10 rounded-2xl md:px-0 px-6">
                                            <a href="https://www.thehindubusinessline.com/economy/agri-business/ankur-capital-omnivore-help-agrizy-raise-4-million-seed-fund/article65289173.ece" target="blank">
                                                <div className="bg-gray-100">
                                                    <div className="px-4 py-2 web">
                                                        <img className="w-full h-64 rounded-lg"
                                                            src={require("assets/img/article7.png").default}
                                                            alt="...">
                                                        </img>
                                                        <h1 className="font-semibold let text-green-100 text-base  md:pt-4">
                                                            Hindu Business Line
                                                        </h1>
                                                        <h1 className=" let text-black text-xs  md:pt-2">
                                                            Ankur Capital, Omnivore Help Agrizy Raise $4 Million Seed Fund
                                                        </h1>
                                                        <div className="md:pt-4 md:pb-2">
                                                            <a href="https://www.thehindubusinessline.com/economy/agri-business/ankur-capital-omnivore-help-agrizy-raise-4-million-seed-fund/article65289173.ece" target="blank">
                                                                <button className="text-sm rounded text-sm border-transparent bg-green-100 text-white font-bold hover:bg-purple-500 py-2 px-6">
                                                                    Read
                                                                </button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div className="lg:w-6/12 md:6/12 md:pb-0 pb-6">
                                    <div className="md:px-4">
                                        <div className="w-full lg:w-12/12 md:w-12/12 pointer md:pb-10 rounded-2xl md:px-0 px-6">
                                            <a href="https://krishijagran.com/industry-news/agrizy-raises-4m-seed-funding-led-by-ankur-capital-to-transform-supply-chain-for-agri-processors/" target="blank">
                                                <div className="bg-gray-100">
                                                    <div className="px-4 py-2 web">
                                                        <img className="w-full h-64 rounded-lg"
                                                            src={require("assets/img/article8.png").default}
                                                            alt="...">
                                                        </img>
                                                        <h1 className="font-semibold let text-green-100 text-base  md:pt-4">
                                                            Krishi Jagran
                                                        </h1>
                                                        <h1 className=" let text-black text-xs  md:pt-2">
                                                            Agrizy Raises $4 Million Seed Funding Led By Ankur Capital To Transform Supply Chain For Agri Processors
                                                        </h1>
                                                        <div className="md:pt-2 md:pb-2">
                                                            <a href="https://krishijagran.com/industry-news/agrizy-raises-4m-seed-funding-led-by-ankur-capital-to-transform-supply-chain-for-agri-processors/" target="blank">
                                                                <button className="text-sm rounded text-sm border-transparent bg-green-100 text-white font-bold hover:bg-purple-500 py-2 px-6">
                                                                    Read
                                                                </button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>




                {/* 3rd row */}
                <div className="w-full lg:w-12/12 md:w-12/12">
                    <div className="flex flex-wrap">
                        <div className="lg:w-6/12 md:6/12">
                            <div className="flex flex-wrap">
                                <div className="lg:w-6/12 md:6/12 md:pb-0 pb-6">
                                    <div className="md:px-4">
                                        <div className="w-full lg:w-12/12 md:w-12/12 pointer md:pb-10 rounded-2xl md:px-0 px-6">
                                            <a href="https://viestories.com/AgriTech-startup-agrizy-raises-4-mn-in-funding/" target="blank">
                                                <div className="bg-gray-100">
                                                    <div className="px-4 py-2 web">
                                                        <img className="w-full h-64 rounded-lg"
                                                            src={require("assets/img/article9.png").default}
                                                            alt="...">
                                                        </img>
                                                        <h1 className="font-semibold let text-green-100 text-base  md:pt-4">
                                                            VIE Stories
                                                        </h1>
                                                        <h1 className=" let text-black text-xs  md:pt-2">
                                                            AgriTech Startup Agrizy Raises $4 Million <br></br> In Funding
                                                        </h1>
                                                        <div className="md:pt-4 md:pb-2">
                                                            <a href="https://viestories.com/AgriTech-startup-agrizy-raises-4-mn-in-funding/" target="blank">
                                                                <button className="text-sm rounded text-sm border-transparent bg-green-100 text-white font-bold hover:bg-purple-500 py-2 px-6">
                                                                    Read
                                                                </button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div className="lg:w-6/12 md:6/12 md:pb-0 pb-6">
                                    <div className="md:px-4">
                                        <div className="w-full lg:w-12/12 md:w-12/12 pointer md:pb-10 rounded-2xl md:px-0 px-6">
                                            <a href="https://economictimes.indiatimes.com/small-biz/sme-sector/agri-tech-company-agrizy-raises-4-million-seed-funding-led-by-ankur-capital/articleshow/90640343.cms" target="blank">
                                                <div className="bg-gray-100">
                                                    <div className="px-4 py-2 web">
                                                        <img className="w-full h-64 rounded-lg"
                                                            src={require("assets/img/article10.png").default}
                                                            alt="...">
                                                        </img>
                                                        <h1 className="font-semibold let text-green-100 text-base  md:pt-4">
                                                            Economic Times
                                                        </h1>
                                                        <h1 className=" let text-black text-xs  md:pt-2">
                                                            AgriTech Company, Agrizy, Raises $4 Million Seed Funding Led By Ankur Capital
                                                        </h1>
                                                        <div className="md:pt-4 md:pb-2">
                                                            <a href="https://economictimes.indiatimes.com/small-biz/sme-sector/agri-tech-company-agrizy-raises-4-million-seed-funding-led-by-ankur-capital/articleshow/90640343.cms" target="blank">
                                                                <button className="text-sm rounded text-sm border-transparent bg-green-100 text-white font-bold hover:bg-purple-500 py-2 px-6">
                                                                    Read
                                                                </button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="lg:w-6/12 md:6/12">
                            <div className="flex flex-wrap">
                                <div className="lg:w-6/12 md:6/12 md:pb-0 pb-6">
                                    <div className="md:px-4">
                                        <div className="w-full lg:w-12/12 md:w-12/12 pointer md:pb-10 rounded-2xl md:px-0 px-6">
                                            <a href="https://www.goodreturns.in/news/AgriTech-startup-agrizy-raises-usd-4-mn-from-investors-including-ankur-capital-1245076.html" target="blank">
                                                <div className="bg-gray-100">
                                                    <div className="px-4 py-2 web">
                                                        <img className="w-full h-64 rounded-lg"
                                                            src={require("assets/img/article11.png").default}
                                                            alt="...">
                                                        </img>
                                                        <h1 className="font-semibold let text-green-100 text-base  md:pt-4">
                                                            Good Returns
                                                        </h1>
                                                        <h1 className=" let text-black text-xs  md:pt-2">
                                                            AgriTech Startup Agrizy Raises USD $4 Million, From Investors Including Ankur Capital
                                                        </h1>
                                                        <div className="md:pt-4 md:pb-2">
                                                            <a href="https://www.goodreturns.in/news/AgriTech-startup-agrizy-raises-usd-4-mn-from-investors-including-ankur-capital-1245076.html" target="blank">
                                                                <button className="text-sm rounded text-sm border-transparent bg-green-100 text-white font-bold hover:bg-purple-500 py-2 px-6">
                                                                    Read
                                                                </button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div className="lg:w-6/12 md:6/12 md:pb-0 pb-6">
                                    <div className="md:px-4">
                                        <div className="w-full lg:w-12/12 md:w-12/12 pointer md:pb-10 rounded-2xl md:px-0 px-6">
                                            <a href="https://flashnews11.com/business/entrepreneurship/agrizy-raises-4-million-seed-funding-led-by-ankur-capital/" target="blank">
                                                <div className="bg-gray-100">
                                                    <div className="px-4 py-2 web">
                                                        <img className="w-full h-64 rounded-lg"
                                                            src={require("assets/img/article12.png").default}
                                                            alt="...">
                                                        </img>
                                                        <h1 className="font-semibold let text-green-100 text-base  md:pt-4">
                                                            Flash News 11
                                                        </h1>
                                                        <h1 className=" let text-black text-xs  md:pt-2">
                                                            AgriTech Firm Agrizy Bags $4 Million Seed Funding Led By Ankur Capital
                                                        </h1>
                                                        <div className="md:pt-4 md:pb-2">
                                                            <a href="https://flashnews11.com/business/entrepreneurship/agrizy-raises-4-million-seed-funding-led-by-ankur-capital/" target="blank">
                                                                <button className="text-sm rounded text-sm border-transparent bg-green-100 text-white font-bold hover:bg-purple-500 py-2 px-6">
                                                                    Read
                                                                </button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>




                {/* 4th row */}
                <div className="w-full lg:w-12/12 md:w-12/12">
                    <div className="flex flex-wrap">
                        <div className="lg:w-6/12 md:6/12">
                            <div className="flex flex-wrap">
                                <div className="lg:w-6/12 md:6/12 md:pb-0 pb-6">
                                    <div className="md:px-4">
                                        <div className="w-full lg:w-12/12 md:w-12/12 pointer md:pb-10 rounded-2xl md:px-0 px-6">
                                            <a href="https://thehitc.com/ankur-capital-agri-tech-company-agrizy-raises-4-million-seed-funding-led-by-ankur-capital/" target="blank">
                                                <div className="bg-gray-100">
                                                    <div className="px-4 py-2 web">
                                                        <img className="w-full h-64 rounded-lg"
                                                            src={require("assets/img/article13.png").default}
                                                            alt="...">
                                                        </img>
                                                        <h1 className="font-semibold let text-green-100 text-base  md:pt-4">
                                                            HITC
                                                        </h1>
                                                        <h1 className=" let text-black text-xs  md:pt-2">
                                                            Ankur Capital: AgriTech Company Agrizy Raises $4 Million Seed Funding Led By Ankur Capital
                                                        </h1>
                                                        <div className="md:pt-4 md:pb-2">
                                                            <a href="https://thehitc.com/ankur-capital-agri-tech-company-agrizy-raises-4-million-seed-funding-led-by-ankur-capital/" target="blank">
                                                                <button className="text-sm rounded text-sm border-transparent bg-green-100 text-white font-bold hover:bg-purple-500 py-2 px-6">
                                                                    Read
                                                                </button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div className="lg:w-6/12 md:6/12 md:pb-0 pb-6">
                                    <div className="md:px-4">
                                        <div className="w-full lg:w-12/12 md:w-12/12 pointer md:pb-10 rounded-2xl md:px-0 px-6">
                                            <a href="https://www.livemint.com/companies/start-ups/agritech-startup-agrizy-raises-4-mn-from-investors-including-ankur-capital-11649066677669.html" target="blank">
                                                <div className="bg-gray-100">
                                                    <div className="px-4 py-2 web">
                                                        <img className="w-full h-64 rounded-lg"
                                                            src={require("assets/img/article14.png").default}
                                                            alt="...">
                                                        </img>
                                                        <h1 className="font-semibold let text-green-100 text-base  md:pt-4">
                                                            Livemint
                                                        </h1>
                                                        <h1 className=" let text-black text-xs md:pt-2">
                                                            AgriTech startup Agrizy Raises $4 Million From Investors Including Ankur Capital
                                                        </h1>
                                                        <div className="md:pt-4 md:pb-2">
                                                            <a href="https://www.livemint.com/companies/start-ups/AgriTech-startup-agrizy-raises-4-mn-from-investors-including-ankur-capital-11649066677669.html" target="blank">
                                                                <button className="text-sm rounded text-sm border-transparent bg-green-100 text-white font-bold hover:bg-purple-500 py-2 px-6">
                                                                    Read
                                                                </button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="lg:w-6/12 md:6/12">
                            <div className="flex flex-wrap">
                                <div className="lg:w-6/12 md:6/12 md:pb-0 pb-6">
                                    <div className="md:px-4">
                                        <div className="w-full lg:w-12/12 md:w-12/12 pointer md:pb-10 rounded-2xl md:px-0 px-6">
                                            <a href="https://economictimes.indiatimes.com/tech/funding/agritech-startup-agrizy-raises-4-million-in-funding-led-by-ankur-capital/articleshow/90642271.cms" target="blank">
                                                <div className="bg-gray-100">
                                                    <div className="px-4 py-2 web">
                                                        <img className="w-full h-64 rounded-lg"
                                                            src={require("assets/img/article15.png").default}
                                                            alt="...">
                                                        </img>
                                                        <h1 className="font-semibold let text-green-100 text-base  md:pt-4">
                                                            ET Tech
                                                        </h1>
                                                        <h1 className=" let text-black text-xs  md:pt-2">
                                                            AgriTech Startup Agrizy Raises $4 Million In Funding Led By Ankur Capital
                                                        </h1>
                                                        <div className="md:pt-4 md:pb-2">
                                                            <a href="https://economictimes.indiatimes.com/tech/funding/AgriTech-startup-agrizy-raises-4-million-in-funding-led-by-ankur-capital/articleshow/90642271.cms" target="blank">
                                                                <button className="text-sm rounded text-sm border-transparent bg-green-100 text-white font-bold hover:bg-purple-500 py-2 px-6">
                                                                    Read
                                                                </button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div className="lg:w-6/12 md:6/12 md:pb-0 pb-6">
                                    <div className="md:px-4">
                                        <div className="w-full lg:w-12/12 md:w-12/12 pointer md:pb-10 rounded-2xl md:px-0 px-6">
                                            <a href="https://www.entrepreneur.com/en-in/news-and-trends/agrizy-raises-4-million-seed-funding-led-by-ankur-capital/423800" target="blank">
                                                <div className="bg-gray-100">
                                                    <div className="px-4 py-2 web">
                                                        <img className="w-full h-64 rounded-lg"
                                                            src={require("assets/img/article16.png").default}
                                                            alt="...">
                                                        </img>
                                                        <h1 className="font-semibold let text-green-100 text-base  md:pt-4">
                                                            Entrepreneur India
                                                        </h1>
                                                        <h1 className=" let text-black text-xs  md:pt-2">
                                                            Agrizy Raises $4 Million Seed Funding Led By Ankur Capital
                                                        </h1>
                                                        <div className="md:pt-4 md:pb-2">
                                                            <a href="https://www.entrepreneur.com/article/423800" target="blank">
                                                                <button className="text-sm rounded text-sm border-transparent bg-green-100 text-white font-bold hover:bg-purple-500 py-2 px-6">
                                                                    Read
                                                                </button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>




                {/* 5th row */}
                <div className="w-full lg:w-12/12 md:w-12/12">
                    <div className="flex flex-wrap">
                        <div className="lg:w-6/12 md:6/12">
                            <div className="flex flex-wrap">
                                <div className="lg:w-6/12 md:6/12 md:pb-0 pb-6">
                                    <div className="md:px-4">
                                        <div className="w-full lg:w-12/12 md:w-12/12 pointer md:pb-10 rounded-2xl md:px-0 px-6">
                                            <a href="https://www.apnnews.com/agrizy-raises-4m-seed-funding-led-by-ankur-capital-to-transform-the-supply-chain-for-agri-processors/" target="blank">
                                                <div className="bg-gray-100">
                                                    <div className="px-4 py-2 web">
                                                        <img className="w-full h-64 rounded-lg"
                                                            src={require("assets/img/article17.png").default}
                                                            alt="...">
                                                        </img>
                                                        <h1 className="font-semibold let text-green-100 text-base  md:pt-4">
                                                            APN News
                                                        </h1>
                                                        <h1 className=" let text-black text-xs  md:pt-2">
                                                            Agrizy Raises $4 Million Seed Funding Led By Ankur Capital To Transform The Supply Chain For Agri Processors
                                                        </h1>
                                                        <div className="md:pt-2 md:pb-2">
                                                            <a href="https://www.apnnews.com/agrizy-raises-4m-seed-funding-led-by-ankur-capital-to-transform-the-supply-chain-for-agri-processors/" target="blank">
                                                                <button className="text-sm rounded text-sm border-transparent bg-green-100 text-white font-bold hover:bg-purple-500 py-2 px-6">
                                                                    Read
                                                                </button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div className="lg:w-6/12 md:6/12 md:pb-0 pb-6">
                                    <div className="md:px-4">
                                        <div className="w-full lg:w-12/12 md:w-12/12 pointer md:pb-10 rounded-2xl md:px-0 px-6">
                                            <a href="https://www.userwalls.news/n/agritech-startup-agrizy-raises-million-funding-led-ankur-capital-3048125/" target="blank">
                                                <div className="bg-gray-100">
                                                    <div className="px-4 py-2 web">
                                                        <img className="w-full h-64 rounded-lg"
                                                            src={require("assets/img/article39.png").default}
                                                            alt="...">
                                                        </img>
                                                        <h1 className="font-semibold let text-green-100 text-base  md:pt-4">
                                                            User Walls
                                                        </h1>
                                                        <h1 className=" let text-black text-xs  md:pt-2">
                                                            AgriTech Firm Agrizy Bags $4 Million Seed Funding Led By Ankur Capital
                                                        </h1>
                                                        <div className="md:pt-4 md:pb-2">
                                                            <a href="https://www.userwalls.com/n/AgriTech-startup-agrizy-raises-million-funding-led-ankur-capital-3048125/" target="blank">
                                                                <button className="text-sm rounded text-sm border-transparent bg-green-100 text-white font-bold hover:bg-purple-500 py-2 px-6">
                                                                    Read
                                                                </button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="lg:w-6/12 md:6/12">
                            <div className="flex flex-wrap">
                                <div className="lg:w-6/12 md:6/12 md:pb-0 pb-6">
                                    <div className="md:px-4">
                                        <div className="w-full lg:w-12/12 md:w-12/12 pointer md:pb-10 rounded-2xl md:px-0 px-6">
                                            <a href="https://www.cnbctv18.com/finance/funding-rundown-omnivores-new-130-million-fund-crypto-giant-ftx-likely-to-invest-in-mpl-enkash-raises-20-million-in-series-b-13045132.htm" target="blank">
                                                <div className="bg-gray-100">
                                                    <div className="px-4 py-2 web">
                                                        <img className="w-full h-64 rounded-lg"
                                                            src={require("assets/img/article19.png").default}
                                                            alt="...">
                                                        </img>
                                                        <h1 className="font-semibold let text-green-100 text-base  md:pt-4">
                                                            CNBC
                                                        </h1>
                                                        <h1 className=" let text-black text-xs  md:pt-2">
                                                            Omnivore Launches New $130 Million Venture Fund Focused On AgriTech And Climate Sustainability
                                                        </h1>
                                                        <div className="md:pt-2 md:pb-2">
                                                            <a href="https://www.cnbctv18.com/finance/funding-rundown-omnivores-new-130-million-fund-crypto-giant-ftx-likely-to-invest-in-mpl-enkash-raises-20-million-in-series-b-13045132.htm" target="blank">
                                                                <button className="text-sm rounded text-sm border-transparent bg-green-100 text-white font-bold hover:bg-purple-500 py-2 px-6">
                                                                    Read
                                                                </button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div className="lg:w-6/12 md:6/12 md:pb-0 pb-6">
                                    <div className="md:px-4">
                                        <div className="w-full lg:w-12/12 md:w-12/12 pointer md:pb-10 rounded-2xl md:px-0 px-6">
                                            <a href="https://www.pehalnews.in/agri-tech-company-agrizy-raises-4-million-seed-funding-led-by-ankur-capital/1823047/" target="blank">
                                                <div className="bg-gray-100">
                                                    <div className="px-4 py-2 web">
                                                        <img className="w-full h-64 rounded-lg"
                                                            src={require("assets/img/article20.png").default}
                                                            alt="...">
                                                        </img>
                                                        <h1 className="font-semibold let text-green-100 text-base  md:pt-4">
                                                            Pehal News
                                                        </h1>
                                                        <h1 className=" let text-black text-xs  md:pt-2">
                                                            AgriTech Firm Agrizy Bags $4 Million Seed Funding Led By Ankur Capital
                                                        </h1>
                                                        <div className="md:pt-4 md:pb-2">
                                                            <a href="https://www.pehalnews.in/agri-tech-company-agrizy-raises-4-million-seed-funding-led-by-ankur-capital/1823047/" target="blank">
                                                                <button className="text-sm rounded text-sm border-transparent bg-green-100 text-white font-bold hover:bg-purple-500 py-2 px-6">
                                                                    Read
                                                                </button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>




                {/* 6th row */}
                <div className="w-full lg:w-12/12 md:w-12/12">
                    <div className="flex flex-wrap">
                        <div className="lg:w-6/12 md:6/12">
                            <div className="flex flex-wrap">
                                <div className="lg:w-6/12 md:6/12 md:pb-0 pb-6">
                                    <div className="md:px-4">
                                        <div className="w-full lg:w-12/12 md:w-12/12 pointer md:pb-10 rounded-2xl md:px-0 px-6">
                                            <a href="https://www.equitypandit.com/AgriTech-startup-agrizy-raises-4-mn-from-ankur-capital/" target="blank">
                                                <div className="bg-gray-100">
                                                    <div className="px-4 py-2 web">
                                                        <img className="w-full h-64 rounded-lg"
                                                            src={require("assets/img/article21.png").default}
                                                            alt="...">
                                                        </img>
                                                        <h1 className="font-semibold let text-green-100 text-base  md:pt-4">
                                                            Equity Pandit
                                                        </h1>
                                                        <h1 className=" let text-black text-xs  md:pt-2">
                                                            AgriTech Startup Agrizy Raises $4 Million From Ankur Capital
                                                        </h1>
                                                        <div className="md:pt-4 md:pb-2">
                                                            <a href="https://www.equitypandit.com/AgriTech-startup-agrizy-raises-4-mn-from-ankur-capital/" target="blank">
                                                                <button className="text-sm rounded text-sm border-transparent bg-green-100 text-white font-bold hover:bg-purple-500 py-2 px-6">
                                                                    Read
                                                                </button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div className="lg:w-6/12 md:6/12 md:pb-0 pb-6">
                                    <div className="md:px-4">
                                        <div className="w-full lg:w-12/12 md:w-12/12 pointer md:pb-10 rounded-2xl md:px-0 px-6">
                                            <a href="https://newsexplorer.net/AgriTech-startup-agrizy-raises-4-million-in-funding-led-by-ankur-capital-s1111589.html" target="blank">
                                                <div className="bg-gray-100">
                                                    <div className="px-4 py-2 web">
                                                        <img className="w-full h-64 rounded-lg"
                                                            src={require("assets/img/article38.png").default}
                                                            alt="...">
                                                        </img>
                                                        <h1 className="font-semibold let text-green-100 text-base  md:pt-4">
                                                            News Explorer
                                                        </h1>
                                                        <h1 className=" let text-black text-xs  md:pt-2">
                                                            AgriTech Startup Agrizy Raises $4 Million In Funding Led By Ankur Capital
                                                        </h1>
                                                        <div className="md:pt-4 md:pb-2">
                                                            <a href="https://newsexplorer.net/AgriTech-startup-agrizy-raises-4-million-in-funding-led-by-ankur-capital-s1111589.html" target="blank">
                                                                <button className="text-sm rounded text-sm border-transparent bg-green-100 text-white font-bold hover:bg-purple-500 py-2 px-6">
                                                                    Read
                                                                </button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="lg:w-6/12 md:6/12">
                            <div className="flex flex-wrap">
                                <div className="lg:w-6/12 md:6/12 md:pb-0 pb-6">
                                    <div className="md:px-4">
                                        <div className="w-full lg:w-12/12 md:w-12/12 pointer md:pb-10 rounded-2xl md:px-0 px-6">
                                            <a href="https://asiatechjournal.com/AgriTech-startup-agrizy-to-develop-solutions-for-agri-processors-raises-4mn-seed-fund/" target="blank">
                                                <div className="bg-gray-100">
                                                    <div className="px-4 py-2 web">
                                                        <img className="w-full h-64 rounded-lg"
                                                            src={require("assets/img/article23.png").default}
                                                            alt="...">
                                                        </img>
                                                        <h1 className="font-semibold let text-green-100 text-base  md:pt-4">
                                                            Asia Tech Journal
                                                        </h1>
                                                        <h1 className=" let text-black text-xs  md:pt-2">
                                                            AgriTech Startup Agrizy To Develop Solutions For Agri Processors Raises $4 Million Seed Fund
                                                        </h1>
                                                        <div className="md:pt-4 md:pb-2">
                                                            <a href="https://asiatechjournal.com/AgriTech-startup-agrizy-to-develop-solutions-for-agri-processors-raises-4mn-seed-fund/" target="blank">
                                                                <button className="text-sm rounded text-sm border-transparent bg-green-100 text-white font-bold hover:bg-purple-500 py-2 px-6">
                                                                    Read
                                                                </button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div className="lg:w-6/12 md:6/12 md:pb-0 pb-6">
                                    <div className="md:px-4">
                                        <div className="w-full lg:w-12/12 md:w-12/12 pointer md:pb-10 rounded-2xl md:px-0 px-6">
                                            <a href="https://pen18.com/agrizy-funding-AgriTech-startup-agrizy-raises-4-million-in-funding-led-by-ankur-capital/" target="blank">
                                                <div className="bg-gray-100">
                                                    <div className="px-4 py-2 web">
                                                        <img className="w-full h-64 rounded-lg"
                                                            src={require("assets/img/article24.png").default}
                                                            alt="...">
                                                        </img>
                                                        <h1 className="font-semibold let text-green-100 text-base  md:pt-4">
                                                            Pen18
                                                        </h1>
                                                        <h1 className=" let text-black text-xs  md:pt-2">
                                                            Agrizy Funding: AgriTech Startup Agrizy Raises $4 Million In Funding Led By Ankur Capital
                                                        </h1>
                                                        <div className="md:pt-4 md:pb-2">
                                                            <a href="https://www.pen18.com/agrizy-funding-AgriTech-startup-agrizy-raises-4-million-in-funding-led-by-ankur-capital/" target="blank">
                                                                <button className="text-sm rounded text-sm border-transparent bg-green-100 text-white font-bold hover:bg-purple-500 py-2 px-6">
                                                                    Read
                                                                </button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/* 7th row */}
                <div className="w-full lg:w-12/12 md:w-12/12">
                    <div className="flex flex-wrap">
                        <div className="lg:w-6/12 md:6/12">
                            <div className="flex flex-wrap">
                                <div className="lg:w-6/12 md:6/12 md:pb-0 pb-6">
                                    <div className="md:px-4">
                                        <div className="w-full lg:w-12/12 md:w-12/12 pointer md:pb-10 rounded-2xl md:px-0 px-6">
                                            <a href="https://thedailycheck.net/AgriTech-startup-agrizy-raises-4-million-in-funding-led-by-ankur-capital/" target="blank">
                                                <div className="bg-gray-100">
                                                    <div className="px-4 py-2 web">
                                                        <img className="w-full h-64 rounded-lg"
                                                            src={require("assets/img/article25.png").default}
                                                            alt="...">
                                                        </img>
                                                        <h1 className="font-semibold let text-green-100 text-base  md:pt-4">
                                                            The Daily Check
                                                        </h1>
                                                        <h1 className=" let text-black text-xs  md:pt-2">
                                                            AgriTech Startup Agrizy Raises $4 Million In Funding Led By Ankur Capital
                                                        </h1>
                                                        <div className="md:pt-4 md:pb-2">
                                                            <a href="https://thedailycheck.net/AgriTech-startup-agrizy-raises-4-million-in-funding-led-by-ankur-capital/" target="blank">
                                                                <button className="text-sm rounded text-sm border-transparent bg-green-100 text-white font-bold hover:bg-purple-500 py-2 px-6">
                                                                    Read
                                                                </button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div className="lg:w-6/12 md:6/12 md:pb-0 pb-6">
                                    <div className="md:px-4">
                                        <div className="w-full lg:w-12/12 md:w-12/12 pointer md:pb-10 rounded-2xl md:px-0 px-6">
                                            <a href="https://www.techinasia.com/india-roundup-appsforbharat-set-raise-30-million" target="blank">
                                                <div className="bg-gray-100">
                                                    <div className="px-4 py-2 web">
                                                        <img className="w-full h-64 rounded-lg"
                                                            src={require("assets/img/article26.png").default}
                                                            alt="...">
                                                        </img>
                                                        <h1 className="font-semibold let text-green-100 text-base  md:pt-4">
                                                            Tech in Asia
                                                        </h1>
                                                        <h1 className=" let text-black text-xs  md:pt-2">
                                                            🇮🇳 Roundup: Spiritual Startup To Raise $30 Million In Series B Money
                                                        </h1>
                                                        <div className="md:pt-4 md:pb-2">
                                                            <a href="https://www.techinasia.com/india-roundup-appsforbharat-set-raise-30-million" target="blank">
                                                                <button className="text-sm rounded text-sm border-transparent bg-green-100 text-white font-bold hover:bg-purple-500 py-2 px-6">
                                                                    Read
                                                                </button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="lg:w-6/12 md:6/12">
                            <div className="flex flex-wrap">
                                <div className="lg:w-6/12 md:6/12 md:pb-0 pb-6">
                                    <div className="md:px-4">
                                        <div className="w-full lg:w-12/12 md:w-12/12 pointer md:pb-10 rounded-2xl md:px-0 px-6">
                                            <a href="https://timesofindia.indiatimes.com/business/startups/companies/AgriTech-startup-agrizy-raises-4-million-from-investors-including-ankur-capital/articleshow/90640885.cms" target="blank">
                                                <div className="bg-gray-100">
                                                    <div className="px-4 py-2 web">
                                                        <img className="w-full h-64 rounded-lg"
                                                            src={require("assets/img/article27.png").default}
                                                            alt="...">
                                                        </img>
                                                        <h1 className="font-semibold let text-green-100 text-base  md:pt-4">
                                                            TOI
                                                        </h1>
                                                        <h1 className=" let text-black text-xs  md:pt-2">
                                                            AgriTech Startup Agrizy Raises $4 Million From Investors Including Ankur Capital
                                                        </h1>
                                                        <div className="md:pt-4 md:pb-2">
                                                            <a href="https://timesofindia.indiatimes.com/business/startups/companies/AgriTech-startup-agrizy-raises-4-million-from-investors-including-ankur-capital/articleshow/90640885.cms" target="blank">
                                                                <button className="text-sm rounded text-sm border-transparent bg-green-100 text-white font-bold hover:bg-purple-500 py-2 px-6">
                                                                    Read
                                                                </button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div className="lg:w-6/12 md:6/12 md:pb-0 pb-6">
                                    <div className="md:px-4">
                                        <div className="w-full lg:w-12/12 md:w-12/12 pointer md:pb-10 rounded-2xl md:px-0 px-6">
                                            <a href="https://www.timesofupdate.com/" target="blank">
                                                <div className="bg-gray-100">
                                                    <div className="px-4 py-2 web">
                                                        <img className="w-full h-64 rounded-lg"
                                                            src={require("assets/img/article28.png").default}
                                                            alt="...">
                                                        </img>
                                                        <h1 className="font-semibold let text-green-100 text-base  md:pt-4">
                                                            Times of Update
                                                        </h1>
                                                        <h1 className=" let text-black text-xs  md:pt-2">
                                                            AgriTech Firm Agrizy Bags $4 Million Seed Funding Led By Ankur Capital
                                                        </h1>
                                                        <div className="md:pt-4 md:pb-2">
                                                            <a href="https://www.timesofupdate.com/buzz-update-ankur-capital-omnivore-help-agrizy-raise-4-million-seed-fund-tou/" target="blank">
                                                                <button className="text-sm rounded text-sm border-transparent bg-green-100 text-white font-bold hover:bg-purple-500 py-2 px-6">
                                                                    Read
                                                                </button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                {/* 8th row */}
                <div className="w-full lg:w-12/12 md:w-12/12">
                    <div className="flex flex-wrap">
                        <div className="lg:w-6/12 md:6/12">
                            <div className="flex flex-wrap">
                                <div className="lg:w-6/12 md:6/12 md:pb-0 pb-6">
                                    <div className="md:px-4">
                                        <div className="w-full lg:w-12/12 md:w-12/12 pointer md:pb-10 rounded-2xl md:px-0 px-6">
                                            <a href="https://newspatrolling.com/agrizy-raises-4m-seed-funding-led-by-ankur-capital-to-transform-the-supply-chain-for-agri-processors/" target="blank">
                                                <div className="bg-gray-100">
                                                    <div className="px-4 py-2 web">
                                                        <img className="w-full h-64 rounded-lg"
                                                            src={require("assets/img/article37.png").default}
                                                            alt="...">
                                                        </img>
                                                        <h1 className="font-semibold let text-green-100 text-base  md:pt-4">
                                                            News Patroling
                                                        </h1>
                                                        <h1 className=" let text-black text-xs md:pt-2">
                                                            Agrizy Raises $4 Million Seed Funding Led By Ankur Capital To Transform The Supply Chain For Agri Processors
                                                        </h1>
                                                        <div className="md:pt-2 md:pb-2">
                                                            <a href="https://newspatrolling.com/agrizy-raises-4m-seed-funding-led-by-ankur-capital-to-transform-the-supply-chain-for-agri-processors/" target="blank">
                                                                <button className="text-sm rounded text-sm border-transparent bg-green-100 text-white font-bold hover:bg-purple-500 py-2 px-6">
                                                                    Read
                                                                </button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div className="lg:w-6/12 md:6/12 md:pb-0 pb-6">
                                    <div className="md:px-4">
                                        <div className="w-full lg:w-12/12 md:w-12/12 pointer md:pb-10 rounded-2xl md:px-0 px-6">
                                            <a href="https://eloep.com/" target="blank">
                                                <div className="bg-gray-100">
                                                    <div className="px-4 py-2 web">
                                                        <img className="w-full h-64 rounded-lg"
                                                            src={require("assets/img/article30.png").default}
                                                            alt="...">
                                                        </img>
                                                        <h1 className="font-semibold let text-green-100 text-base  md:pt-4">
                                                            ELOEP
                                                        </h1>
                                                        <h1 className=" let text-black text-xs  md:pt-2">
                                                            AgriTech Firm Agrizy Bags $4 Million Seed Funding Led By Ankur Capital
                                                        </h1>
                                                        <div className="md:pt-4 md:pb-2">
                                                            <a href="https://eloep.com/ankur-capital-agri-tech-company-agrizy-raises-4-million-in-seed-funding-led-by-ankur-capital/" target="blank">
                                                                <button className="text-sm rounded text-sm border-transparent bg-green-100 text-white font-bold hover:bg-purple-500 py-2 px-6">
                                                                    Read
                                                                </button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="lg:w-6/12 md:6/12">
                            <div className="flex flex-wrap">
                                <div className="lg:w-6/12 md:6/12 md:pb-0 pb-6">
                                    <div className="md:px-4">
                                        <div className="w-full lg:w-12/12 md:w-12/12 pointer md:pb-10 rounded-2xl md:px-0 px-6">
                                            <a href="https://yourstory.com/2022/04/startup-news-and-updates-daily-roundup-april-4-2022/amp" target="blank">
                                                <div className="bg-gray-100">
                                                    <div className="px-4 py-2 web">
                                                        <img className="w-full h-64 rounded-lg"
                                                            src={require("assets/img/article31.png").default}
                                                            alt="...">
                                                        </img>
                                                        <h1 className="font-semibold let text-green-100 text-base  md:pt-4">
                                                            Your Story
                                                        </h1>
                                                        <h1 className=" let text-black text-xs  md:pt-2">
                                                            AgriTech Firm Agrizy Bags $4 Million Seed Funding Led By Ankur Capital
                                                        </h1>
                                                        <div className="md:pt-4 md:pb-2">
                                                            <a href="https://yourstory.com/2022/04/startup-news-and-updates-daily-roundup-april-4-2022/amp" target="blank">
                                                                <button className="text-sm rounded text-sm border-transparent bg-green-100 text-white font-bold hover:bg-purple-500 py-2 px-6">
                                                                    Read
                                                                </button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div className="lg:w-6/12 md:6/12 md:pb-0 pb-6">
                                    <div className="md:px-4">
                                        <div className="w-full lg:w-12/12 md:w-12/12 pointer md:pb-10 rounded-2xl md:px-0 px-6">
                                            <a href="https://www.freepressjournal.in/business/AgriTech-startup-agrizy-raises-4-mn-from-ankur-capital-others" target="blank">
                                                <div className="bg-gray-100">
                                                    <div className="px-4 py-2 web">
                                                        <img className="w-full h-64 rounded-lg"
                                                            src={require("assets/img/article32.png").default}
                                                            alt="...">
                                                        </img>
                                                        <h1 className="font-semibold let text-green-100 text-base  md:pt-4">
                                                            The Free Press Journal
                                                        </h1>
                                                        <h1 className=" let text-black text-xs  md:pt-2">
                                                            AgriTech Startup Agrizy Raises $4 Million From Ankur Capital
                                                        </h1>
                                                        <div className="md:pt-4 md:pb-2">
                                                            <a href="https://www.freepressjournal.in/business/AgriTech-startup-agrizy-raises-4-mn-from-ankur-capital-others" target="blank">
                                                                <button className="text-sm rounded text-sm border-transparent bg-green-100 text-white font-bold hover:bg-purple-500 py-2 px-6">
                                                                    Read
                                                                </button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* 9th row */}
                <div className="w-full lg:w-12/12 md:w-12/12">
                    <div className="flex flex-wrap">
                        <div className="lg:w-6/12 md:6/12">
                            <div className="flex flex-wrap">
                                <div className="lg:w-6/12 md:6/12 md:pb-0 pb-6">
                                    <div className="md:px-4">
                                        <div className="w-full lg:w-12/12 md:w-12/12 pointer md:pb-10 rounded-2xl md:px-0 px-6">
                                            <a href="https://agrospectrumindia.com/2022/04/04/agrizy-raises-4m-in-seed-funding-led-by-ankur-capital.html" target="blank">
                                                <div className="bg-gray-100">
                                                    <div className="px-4 py-2 web">
                                                        <img className="w-full h-64 rounded-lg"
                                                            src={require("assets/img/article33.png").default}
                                                            alt="...">
                                                        </img>
                                                        <h1 className="font-semibold let text-green-100 text-base  md:pt-4">
                                                            Agro Spectrum
                                                        </h1>
                                                        <h1 className=" let text-black text-xs  md:pt-2">
                                                            Agrizy Raises $4 Million In Seed Funding Led By Ankur Capital
                                                        </h1>
                                                        <div className="md:pt-4 md:pb-2">
                                                            <a href="https://agrospectrumindia.com/2022/04/04/agrizy-raises-4m-in-seed-funding-led-by-ankur-capital.html" target="blank">
                                                                <button className="text-sm rounded text-sm border-transparent bg-green-100 text-white font-bold hover:bg-purple-500 py-2 px-6">
                                                                    Read
                                                                </button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div className="lg:w-6/12 md:6/12 md:pb-0 pb-6">
                                    <div className="md:px-4">
                                        <div className="w-full lg:w-12/12 md:w-12/12 pointer md:pb-10 rounded-2xl md:px-0 px-6">
                                            <a href="https://business-journal.in/economy/ankur-capital-omnivore-help-agrizy-raise-4-million-seed-fund-business-journal/" target="blank">
                                                <div className="bg-gray-100">
                                                    <div className="px-4 py-2 web">
                                                        <img className="w-full h-64 rounded-lg"
                                                            src={require("assets/img/article34.png").default}
                                                            alt="...">
                                                        </img>
                                                        <h1 className="font-semibold let text-green-100 text-base  md:pt-4">
                                                            Business Journal
                                                        </h1>
                                                        <h1 className=" let text-black text-xs  md:pt-2">
                                                            AgriTech Firm Agrizy Bags $4 Million Seed Funding Led By Ankur Capital
                                                        </h1>
                                                        <div className="md:pt-4 md:pb-2">
                                                            <a href="https://business-journal.in/economy/ankur-capital-omnivore-help-agrizy-raise-4-million-seed-fund-business-journal/" target="blank">
                                                                <button className="text-sm rounded text-sm border-transparent bg-green-100 text-white font-bold hover:bg-purple-500 py-2 px-6">
                                                                    Read
                                                                </button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="lg:w-6/12 md:6/12">
                            <div className="flex flex-wrap">
                                <div className="lg:w-6/12 md:6/12 md:pb-0 pb-6">
                                    {/*<div className="md:px-4">
                                        <div className="w-full lg:w-12/12 md:w-12/12 pointer md:pb-10 rounded-2xl md:px-0 px-6">
                                            <div className="bg-gray-100">
                                                <div className="px-4 py-2 web">
                                                    <img className="w-full h-64 rounded-lg"
                                                        src={require("assets/img/article1.png").default}
                                                        alt="...">
                                                    </img>
                                                    <h1 className="font-semibold let text-green-100 text-base  md:pt-4">
                                                        Infinity Web
                                                    </h1>
                                                    <h1 className=" let text-black text-xs  md:pt-2">
                                                        AgriTech Firm Agrizy Bags $4 Million Seed Funding Led By Ankur Capital
                                                    </h1>
                                                    <div className="md:pt-4 md:pb-2">
                                                        <a href="https://infinityweb.co.in/AgriTech-startup-agrizy-raises-4-mn-from-investors-including-ankur-capital" target="blank">
                                                            <button className="text-sm rounded text-sm border-transparent bg-green-100 text-white font-bold hover:bg-purple-500 py-2 px-6">
                                                                Read
                                                            </button>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                </div>*/}
                                </div>

                                <div className="lg:w-6/12 md:6/12">
                                    {/*<div className="md:px-4">
                                        <div className="w-full lg:w-12/12 md:w-12/12 pointer md:pb-10 rounded-2xl md:px-0 px-6">
                                            <div className="bg-gray-100">
                                                <div className="px-4 py-2 web">
                                                    <img className="w-full h-64 rounded-lg"
                                                        src={require("assets/img/article1.png").default}
                                                        alt="...">
                                                    </img>
                                                    <h1 className="font-semibold let text-green-100 text-base  md:pt-4">
                                                        Tech Blog India
                                                    </h1>
                                                    <h1 className=" let text-black text-xs  md:pt-2">
                                                        AgriTech Firm Agrizy Bags $4 Million Seed Funding Led By Ankur Capital
                                                    </h1>
                                                    <div className="md:pt-4 md:pb-2">
                                                        <a href="https://techblogindia.co.in/startups/AgriTech-startup-agrizy-raised-4-million-in-seed-led-by-ankur-capital/" target="blank">
                                                            <button className="text-sm rounded text-sm border-transparent bg-green-100 text-white font-bold hover:bg-purple-500 py-2 px-6">
                                                                Read
                                                            </button>
                                                        </a>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                            </div>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/* 10th row */}
                <div className="w-full lg:w-12/12 md:w-12/12">
                    <div className="flex flex-wrap">
                        <div className="lg:w-6/12 md:6/12">
                            <div className="flex flex-wrap">
                                <div className="lg:w-6/12 md:6/12">
                                </div>
                                <div className="lg:w-6/12 md:6/12">
                                </div>
                            </div>
                        </div>
                        <div className="lg:w-6/12 md:6/12">
                            <div className="flex flex-wrap">
                                <div className="lg:w-6/12 md:6/12 md:pb-0 pb-6">
                                </div>
                                <div className="lg:w-6/12 md:6/12 md:pb-0 pb-6">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


































            </section>
            <Footer />
        </>
    );
}