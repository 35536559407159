/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
// components

import IndexDropdown from "components/Dropdowns/IndexDropdown.js";

export default function Navbar(props) {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  const [dropOpen, setdropOpen] = React.useState(false);

  return (
    <>
      <nav className="top-0 fixed z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg bg-white md:block hidden ">
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <Link to="/index">
              <img
                className="w-24"
                src={require("assets/img/logoagg.png").default}
              ></img>
            </Link>
            <button
              className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <i className="fas fa-bars"></i>
            </button>
          </div>
          <div
            className={
              "lg:flex flex-grow items-center bg-white lg:bg-opacity-0 lg:shadow-none" +
              (navbarOpen ? " block" : " hidden")
            }
            id="example-navbar-warning"
          >
            <button
              className="lg:static fixed inset-0 bg-black bg-opacity-25 cursor-default"
              onClick={(e) => {
                setNavbarOpen(false);
              }}
            ></button>
            {/*<ul className="flex flex-col lg:flex-row list-none mr-auto">
              <li className="flex items-center">
                <a
                  className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-lg fff  font-normal"
                  href="https://www.creative-tim.com/learning-lab/tailwind/react/overview/notus?ref=nr-index-navbar"
                >
                  <i className="text-blueGray-400 far fa-file-alt text-lg leading-lg mr-2" />{" "}
                  Docs
                </a>
              </li>
          </ul>*/}
            <ul className="absolute right-0 top-0 z-40 w-10/12 px-4 lg:px-0 lg:w-auto  lg:h-auto md:h-auto h-screen  lg:static bg-white flex flex-col lg:flex-row list-none lg:ml-auto">
              <button
                className="cursor-pointer pb-16 text-3xl text-black absolute right-0  px-6 py-2 border-0 rounded  block lg:hidden outline-none focus:outline-none "
                type="button"
                onClick={() => setNavbarOpen(!navbarOpen)}
              >
                <i class="fas fa-times"></i>
              </button>

              <li className="flex items-center py-2 px-2">
                <a href="/index">
                  <div
                    className=" fff text-green-100 font-semibold  text-base py-2 md:block hidden uppercase"
                    type="button"
                  >
                    Home
                  </div>
                </a>
              </li>
              <li className="flex items-center py-2 px-2">
                <a href="/about">
                  <div
                    className=" fff text-green-100 font-semibold  text-base py-2 md:block hidden uppercase"
                    type="button"
                  >
                    About Us
                  </div>
                </a>
              </li>
               {/* <IndexDropdown /> */}
              <li className="flex items-center py-2 px-2">
                <a href="/career">
                  <div
                    className=" fff text-green-100 font-semibold  text-base py-2 md:block hidden uppercase"
                    type="button"
                  >
                    Career
                  </div>
                </a>
              </li>
              <li className="flex items-center py-2 px-2">
                <a href="/contact">
                  <div
                    className=" fff text-green-100 font-semibold  text-base py-2 md:block hidden uppercase"
                    type="button"
                  >
                    Contact Us
                  </div>
                </a>
              </li>

              <li className="flex items-center px-2">
                <a
                  href="https://play.google.com/store/apps/details?id=in.agrizy.app"
                  target="blank"
                >
                  <img
                    className="w-24"
                    src={require("assets/img/googleplay.webp").default}
                  ></img>
                </a>
              </li>
              <li className="flex items-center px-2">
                <a
                  href="https://apps.apple.com/in/app/agrizy/id6447946426"
                  aria-label="link to App Store"
                  target="blank"
                >
                  <img
                    className="w-24"
                    src={require("assets/img/app-menu-apple.webp").default}
                  ></img>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <section>
        {/* Mobile Menu */}
        <nav className="top-0 fixed z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg bg-white md:hidden block ">
          <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
            <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
              <a href="/index">
                <img
                  className="w-24"
                  src={require("assets/img/logoagg.png").default}
                ></img>
              </a>
              <span class="container">
                <span class="checkbox-container">
                  <input class="checkbox-trigger" type="checkbox" />
                  <div class="menu-content ">
                    <ul>
                      <li>
                        <div className="font-normal border-b fnt text-green-100 py-2 px-6">
                          <a href="/index">HOME</a>
                        </div>
                      </li>
                      <li>
                        <div className="font-normal border-b fnt text-green-100 py-2 px-6">
                          <a href="/about">ABOUT US</a>
                        </div>
                      </li>
                      {/* <li>
                        <div className="font-normal border-b fnt text-green-100 py-2 px-6">
                          <a href="/pharmaceutical.html"> PHARMACEUTICAL</a>
                        </div>
                      </li> */}
                      <li>
                        <div className="font-normal border-b fnt text-green-100 py-2 px-6">
                          <a href="/career">CAREER</a>
                        </div>
                      </li>
                      <li>
                        <div className="font-normal border-b fnt text-green-100 py-2 px-6">
                          <a href="/contact">CONTACT US</a>
                        </div>
                      </li>
                      <li className="flex items-center px-6">
                        <a
                          href="https://play.google.com/store/apps/details?id=in.agrizy.app"
                          target="blank"
                        >
                          <img
                            className="w-24"
                            src={require("assets/img/googleplay.webp").default}
                          ></img>
                        </a>
                      </li>
                      <li className="flex items-center px-6">
                        <a
                          href="https://apps.apple.com/in/app/agrizy/id6447946426"
                          aria-label="link to App Store"
                          target="blank"
                        >
                          <img
                            className="w-24"
                            src={
                              require("assets/img/app-menu-apple.webp").default
                            }
                          ></img>
                        </a>
                      </li>
                    </ul>
                    <span class="hamburger-menu"></span>
                  </div>
                </span>
              </span>
            </div>
          </div>
        </nav>
      </section>
    </>
  );
}
