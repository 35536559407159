import React, { useState } from "react";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";

export default function Terms() {
    return (
        <>
            <IndexNavbar fixed />
            <section className="  bg-no-repeat header relative justify-center items-center md:pt-24 pt-24 let heigt" >
            </section>
            <div className="container mx-auto md:px-16 px-4 md:pb-10">
                <div>
                    <h1 className="text-black font-bold md:text-4xl text-2xl text-center md:pt-16">
                        Terms and Conditions
                    </h1>
                </div>
                <h1 className="text-green-100 font-semibold md:text-2xl text-xl  md:pt-16 pt-6">
                    Welcome to Agrizy PVT. LTD.
                </h1>
                <p className="text-sm md:pt-4 pt-4">
                    These terms and conditions outline the rules and regulations for the use of agrizy's Website.
                    Agrizy is located at: 3rd Floor of Krishna Complex, BDA Site No. 2339, 17th, Cross, 27th Main Road, Sector 1, HSR Layout, Bengaluru, Karnataka 560102.

                </p>
                <p className="text-sm md:pt-4 pt-4">
                    By accessing this website we assume you accept these terms and conditions in full.
                    Do not continue to use Agrizy's website if you do not accept all of the terms and conditions stated on this page.
                </p>
                <p className="text-sm md:pt-4 pt-4">
                    The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer
                    Notice and any or all Agreements: "Client", "You" and "Your" refers to you, the person accessing
                    this website and accepting the Company's terms and conditions. "The Company", "Ourselves", "We",
                    "Our" and "Us", refers to our Company. "Party", "Parties", or "Us", refers to both the Client and
                    ourselves, or either the Client or ourselves. All terms refer to the offer, acceptance and consideration
                    of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner,
                    whether by formal meetings of a fixed duration, or any other means, for the express purpose of meeting the
                    Client's needs in respect of provision of the Company's stated services/products, in accordance with and
                    subject to, prevailing law of India. Any use of the above terminology or other words in the singular,
                    plural, capitalisation and/or he/she or they, are taken as interchangeable and therefore as referring to same.
                </p>
                <h1 className="text-green-100 font-semibold text-xl md:text-2xl  md:pt-8 pt-6">
                    Cookies
                </h1>
                <p className="text-sm md:pt-4 pt-4">
                    We employ the use of cookies. By using Agrizy's website you consent to the use of cookies in
                    accordance with Agrizy's privacy policy. Most of the modern day interactive website use cookies
                    to enable us to retrieve user details for each visit. Cookies are used in some areas of our site
                    to enable the functionality of this area and ease of use for those people visiting. Some of our
                    affiliate/advertising partners may also use cookies.
                </p>
                <h1 className="text-green-100 font-semibold text-xl md:text-2xl  md:pt-8 pt-6">
                    License
                </h1>
                <p className="text-sm md:pt-4 pt-4">
                    Unless otherwise stated, Agrizy and/or it's licensors own the intellectual property rights
                    for all material on Agrizy. All intellectual property rights are reserved. You may view and/or
                    print pages from <a className="font-bold text-green-100" href="https://agrizy.in/">
                        agrizy.in
                    </a> for your own personal use subject to restrictions
                    set in these terms and conditions.
                </p>
                <h1 className="text-green-100 font-semibold text-xl md:text-2xl  md:pt-8 pt-6">
                    You Must Not
                </h1>
                <p className="text-sm md:pt-4 pt-4">
                    Republish material from <a className="font-bold text-green-100" href="https://agrizy.in/">
                        agrizy.in
                    </a>  Sell, rent or sub-license material from <a className="font-bold text-green-100" href="https://agrizy.in/">
                        agrizy.in
                    </a>  Reproduce, duplicate or copy material from <a className="font-bold text-green-100" href="https://agrizy.in/">
                        agrizy.in
                    </a>  Redistribute content from Agrizy (unless content is specifically made for redistribution).
                </p>
                <h1 className="text-green-100 font-semibold text-xl md:text-2xl  md:pt-8 pt-6">
                    Hyperlinking To Our Content
                </h1>
                <p className="text-sm md:pt-4 pt-4">
                    The following organizations may link to our Website without prior written approval:<br></br>
                    Government agencies;<br></br>
                    Search engines;<br></br>
                    News organizations;<br></br>
                    Online directory distributors when they list us in the directory may link to our Website in the same manner
                    as they hyperlink to the Websites of other listed business and system wide: Accredited Businesses except
                    soliciting non-profit organizations, charity shopping malls and charity fundraising groups which may not
                    hyperlink to our Website.
                </p>
                <p className="text-sm md:pt-4 pt-4">
                    These organizations may link to our home page, to publications or to other Website information so long as the link:<br></br>
                    (a) is not in any way misleading;<br></br>
                    (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products or services; and<br></br>
                    (c) fits within the context of the linking party's site.
                </p>
                <p className="text-sm md:pt-4 pt-4">
                    We may consider and approve in our sole discretion other link requests from the following types of organizations:
                </p>
                <p className="text-sm md:pt-4 pt-4">
                    We will approve link requests from these organizations if we determine that:
                    <br></br>(a) the link would not reflect unfavorably on us or our accredited businesses (for example, trade associations or other organizations representing inherently suspect types of business, such as work-at-home opportunities, shall not be allowed to link).
                    <br></br>(b) the organization does not have an unsatisfactory record with us.
                    <br></br>(c) the benefit to us from the visibility associated with the hyperlink outweighs the absence of Agrizy.
                    <br></br>(d) where the link is in the context of general resource information or is otherwise consistent with editorial content in a newsletter or similar product furthering the mission of the organization.
                </p>
                <p className="text-sm md:pt-4 pt-4">
                    These organizations may link to our home page, to publications or to other Website information so long as the link:
                    <br></br>(a) is not in any way misleading.
                    <br></br>(b) does not falsely imply sponsorship, endorsement or approval of the linking party and it's products or services.<br></br>
                    (c) fits within the context of the linking party's site.
                </p>
                <p className="text-sm md:pt-4 pt-4">
                    If you are among the organizations listed in paragraph 2 above and are interested in linking to our
                    website, you must notify us by sending an e-mail to hello@agrizy.in<br></br>
                    Please include your name, your organization name, contact information (such as a phone number and/or e-mail address)
                    as well as the URL of your site, a list of any URL from which you intend to link to our Website, and a list of the
                    URL on our site to which you would like to link. Allow 2-3 weeks for a response.
                </p>
                <p className="text-sm md:pt-4 pt-4">
                    Approved organizations may hyperlink to our Website as follows:<br></br>
                    By use of our corporate name or<br></br>
                    By use of the uniform resource locator (Web address) being linked to or<br></br>
                    By use of any other description of our Website or material being linked to that makes sense within the context and format of content on the linking party's site.
                </p>
                <p className="text-sm md:pt-4 pt-4">
                    No use of Agrizy's logo or other artwork will be allowed for linking absent a trademark license agreement.
                </p>
                <h1 className="text-green-100 font-semibold text-xl md:text-2xl  md:pt-8 pt-6">
                    Iframes
                </h1>
                <p className="text-sm md:pt-4 pt-4">
                    Without prior approval and express written permission, you may not create frames around our Web
                    pages or use other techniques that alter in any way the visual presentation or appearance of our Website.
                </p>
                <h1 className="text-green-100 font-semibold text-xl md:text-2xl  md:pt-12 pt-6">
                    Reservation Of Rights
                </h1>
                <p className="text-sm md:pt-4 pt-4">
                    We reserve the right at any time and in its sole discretion to request that you remove all links
                    or any particular link to our Website. You agree to immediately remove all links to our Website
                    upon such request. We also reserve the right to amend these terms and conditions and its linking
                    policy at any time. By continuing to link to our Website, you agree to be bound to and abide by these
                    linking terms and conditions.<br></br>
                    Removal of links from our website If you find any link on our Website or any linked website objectionable for any reason,
                    you may contact us about this. We will consider requests to remove links but will have no obligation to do so or to respond
                    directly to you.<br></br>
                    Whilst we endeavour to ensure that the information on this website is correct, we do not warrant its completeness or accuracy
                    nor do we commit to ensuring that the website remains available or that the material on the website is kept up to date.<br></br><br></br>
                    The app provides users with the ability to download and view ledger information for their accounts. 
                    It is important to note that the ledger information provided through the app is approximate and for reference purposes only.
                    For actual and precise financial data, users are required to contact the finance team via email at [finance@agrizy.in]. 
                    The finance team will provide users with accurate and detailed financial information upon request. 
                    By using the app, users acknowledge and agree that the ledger information presented is not guaranteed to be fully accurate or up-to-date and that any discrepancies or queries regarding financial data should be directed to the finance team for resolution
                </p>
                <h1 className="text-green-100 font-semibold text-xl md:text-2xl  md:pt-12 pt-6">
                    Content Liability
                </h1>
                <p className="text-sm md:pt-4 pt-4">
                    We shall have no responsibility or liability for any content appearing on your Website.
                    You agree to indemnify and defend us against all claims arising out of or based upon your Website.
                    No links may appear on any page on your Website or within any context containing content or materials
                    that may be interpreted as libelous, obscene or criminal or which infringes, otherwise violates
                    or advocates the infringement or other violation of, any third party rights.
                </p>
                <h1 className="text-green-100 font-semibold text-xl md:text-2xl  md:pt-12 pt-6">
                    Disclaimer
                </h1>
                <p className="text-sm md:pt-4 pt-4">
                    To the maximum extent permitted by applicable law, we exclude all representations,
                    warranties and conditions relating to our website and the use of this website (including,
                    without limitation, any warranties implied by law in respect of satisfactory quality, fitness
                    for purpose and/or the use of reasonable care and skill). Nothing in this disclaimer will:
                </p>
                <p className="text-sm md:pt-4 pt-4">
                    limit or exclude our or your liability for death or personal injury resulting from negligence.<br></br>
                    limit or exclude our or your liability for fraud or fraudulent misrepresentation.<br></br>
                    limit any of our or your liabilities in any way that is not permitted under applicable law or<br></br>
                    exclude any of our or your liabilities that may not be excluded under applicable law.
                </p>
                <p className="text-sm md:pt-4 pt-4 pb-6">
                    The limitations and exclusions of liability set out in this section and elsewhere in this disclaimer:<br></br>
                    (a) are subject to the preceding paragraph.<br></br>
                    (b) govern all liabilities arising under the disclaimer or in relation to the subject matter of this disclaimer,
                    including liabilities arising in contract, in tort (including negligence) and for breach of statutory duty.<br></br>
                    To the extent that the website and the information and services on the website are provided free of charge,
                    we will not be liable for any loss or damage of any nature.
                </p>
            </div>
            <Footer />
        </>
    );
}