import React, { Component } from "react";
import Slider from "react-slick";

export default class CenterMode extends Component {
  render() {

    const NextArrow = ({ onClick }) => {
        return (
            <div className="arrow next" onClick={onClick}>
                <i className="text-black px-2 py-2 fas fa-angle-right text-lg leading-lg " />
            </div>
        )
    }

    const PrevArrow = ({ onClick }) => {
        return (
            <div className="arrow prev" onClick={onClick}>
                <i className="text-black px-2 py-2 fas fa-angle-left text-lg leading-lg " />
            </div>
        )
    }

   const settings = {
        infinite: true,
        LazyLoad: true,
        Speed: 300,
        slideToShow: 3,
        centerMode: true,
        centerPadding: 90,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
       

    };
    return (
      <div className="test1">
        <h2>Center Mode</h2>
        <Slider {...settings}>
          <div>
            <div className="bg-green border flex flex-wrap">
            <img
                  alt="..."
                  className=" font-extrabold iconnss w-16 rounded-full p-2 lg:ml-1 "
                  src={require("assets/img/rightprice.png").default} />
                  <div className="py-2 text-xl text-black font-bold px-12">
                  Right price
                  </div>
                  <p className="text-base md:pt-8 font-normal px-12">
                  There are no hidden charges. Right price and quality is delivered.
                  </p>
            </div>
          </div>
          <div className="bg-green border flex flex-wrap">
            <img
                  alt="..."
                  className=" font-extrabold iconnss  w-16 p-2 lg:ml-1 "
                  src={require("assets/img/rightprice.png").default} />
                  <div className="py-2 text-xl text-black font-bold px-12">
                  Right price
                  </div>
                  <p className="text-base md:pt-8 font-normal px-12">
                  There are no hidden charges. Right price and quality is delivered.
                  </p>
            </div>
          <div>
            <h3></h3>
          </div>
          <div>
            <h3>4</h3>
          </div>
          <div>
            <h3>5</h3>
          </div>
          <div>
            <h3>6</h3>
          </div>
        </Slider>
      </div>
    );
  }
}