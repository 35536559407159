import React, { useState } from "react";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";



export default function About() {
    return (
        <>
            <IndexNavbar fixed />
            <section className=" container mx-auto header relative justify-center items-center md:pt-36 pt-32 let">
                <h1 className="md:text-4xl text-2xl text-center font-bold md:pt-4 text-green-100">
                    ABOUT US
                </h1>
                <div className="text-md text-center  md:px-28 pt-2 px-2">
                    Imagine a world where your agri supplies are procured at best price, quality and delivered hassle-free.
                    Agrizy, India's fastest growing B2B platform in the agri processing industry, makes this possible.
                    <h1 className="pt-4">
                        Our team deeply understands the challenges faced by various stakeholders in the agri processing supply
                        chain and is committed to make agri processing more efficient.

                        Agrizy connects the fragmented Agri Suppliers and Agri Processing Units (APUs) across the globe.
                        It focuses on not only the efficient discovery of suppliers and APUs but also on the end-to-end fulfilment.
                    </h1>
                </div>
            </section>

            <section className="md:pt-12 bg-no-repeat pt-6">
                <div className="w-full lg:w-12/12 md:w-12/12 " >
                    <img
                        alt="..."
                        className="w-full"
                        src={require("assets/img/team/team_photo.png").default} />
                </div>
            </section>
            <section className="bgred">
                <div className="container mx-auto md:px-32 px-2 md:block hidden">
                    <h1 className="text-left text-black md:text-base text-xs md:pb-16 let">
                    We challenge the status quo by being curious and dare to execute innovative ideas by being courageous. Made up of intelligent engineers, data analysts, dedicated sales and business developers, creative designers and smart leaders. We’re agri-tech obsessed, transforming the agri-processing supply chain, one innovation at a time.
                    </h1>
                </div>
                <div className="container mx-auto md:px-32 px-2 md:hidden block">
                    <h1 className="text-left text-black md:text-base text-xs md:pb-16 pb-6 let">
                    We challenge the status quo by being curious and dare to execute innovative ideas by being courageous. Made up of intelligent engineers, data analysts, dedicated sales and business developers, creative designers and smart leaders. 
                    </h1>
                </div>
            </section>

            {/* Team Section */} {/* Desktop View */}
            <section className="md:pt-32 md:block hidden">
                <div className="w-full lg:w-12/12 md:w-12/12 ">
                    <div className="flex flex-wrap justify-center">
                        <div class="card">
                            <img
                                alt="..."
                                className=" font-extrabold"
                                src={require("assets/img/team/ABHINAV.png").default} />
                            <div class="data">
                                <p className="font-bold text-base fnt text-black">
                                    Abhinav Bhardwaj
                                </p>
                                <p className="text-sm let text-black">
                                    Regional Sales Head
                                </p>
                            </div>
                        </div>
                        <div class="card ">
                            <img
                                alt="..."
                                className=" font-extrabold"
                                src={require("assets/img/team/ABHISHEK.png").default} />
                            <div class="data">
                                <p className="font-bold text-base fnt text-black">
                                    Abhishek Shrivastava
                                </p>
                                <p className="text-sm let text-black">
                                    Team Lead - Sales
                                </p>
                            </div>
                        </div>
                        <div class="card ">
                            <img
                                alt="..."
                                className=" font-extrabold"
                                src={require("assets/img/team/aman_kumar.png").default} />
                            <div class="data">
                                <p className="font-bold text-base fnt text-black">
                                    Aman Kumar
                                </p>
                                <p className="text-sm let text-black">
                                    Partner - Strategy And Business
                                </p>
                            </div>
                        </div>
                        <div class="card ">
                            <img
                                alt="..."
                                className=" font-extrabold"
                                src={require("assets/img/team/abhishek_saraswat.png").default} />
                            <div class="data">
                                <p className="font-bold text-base fnt text-black">
                                    Abhishek Saraswat
                                </p>
                                <p className="text-sm let text-black">
                                Senior Manager - Finance
                                </p>
                            </div>
                        </div>

                        <div class="card ">
                            <img
                                alt="..."
                                className=" font-extrabold"
                                src={require("assets/img/team/Chandrika.jpg").default} />
                            <div class="data">
                                <p className="font-bold text-base fnt text-black ">
                                    Chandrika Kathayat
                                </p>
                                <p className="let text-sm text-black">
                                    Business Partner - HR
                                </p>
                            </div>
                        </div>
                        <div class="card ">
                            <img
                                alt="..."
                                className=" font-extrabold"
                                src={require("assets/img/team/karthi.png").default} />
                            <div class="data">
                                <p className="font-bold text-base fnt text-black ">
                                    Karthik Sundararaman
                                </p>
                                <p className="let text-sm text-black">
                                    Head of Growth and Strategy
                                </p>
                            </div>
                        </div>

                        <div class="card pl-1">
                            <img
                                alt="..."
                                className=" font-extrabold"
                                src={require("assets/img/team/MARKISH ARUN.png").default} />
                            <div class="data">
                                <p className="font-bold fnt text-base  text-black">
                                    Markish Arun
                                </p>
                                <p className=" text-sm let text-black">
                                    Head of Engineering and Products
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* 2nd Row */}
            <section className="md:block hidden">
                <div className="w-full lg:w-12/12 md:w-12/12">
                    <div className="flex flex-wrap justify-center">
                        <div class="card">
                                <img
                                    alt="..."
                                    className=" font-extrabold"
                                    src={require("assets/img/team/MAHENDERAN.png").default} />
                                <div class="data">
                                    <p className="font-bold text-base fnt text-black">
                                        Mahendran G
                                    </p>
                                    <p className="text-sm let text-black">
                                        Software Development Engineer
                                    </p>
                                </div>
                            </div>
                        <div class="card">
                            <img
                                alt="..."
                                className=" font-extrabold"
                                src={require("assets/img/team/mayank.png").default} />
                            <div class="data">
                                <p className="font-bold fnt text-base  text-black">
                                    Mayank Shukla
                                </p>
                                <p className=" text-sm let text-black">
                                    Key Account Manager
                                </p>
                            </div>
                        </div>
                        <div class="card">
                            <img
                                alt="..."
                                className=" font-extrabold"
                                src={require("assets/img/team/mansi.png").default} />
                            <div class="data">
                                <p className="font-bold text-base fnt text-black">
                                    Manasi Das
                                </p>
                                <p className="text-sm let text-black">
                                    Communications Lead
                                </p>
                            </div>
                        </div>

                        <div class="card">
                            <img
                                alt="..."
                                className=" font-extrabold"
                                src={require("assets/img/team/PAUL.png").default} />
                            <div class="data">
                                <p className="font-bold text-base fnt text-black">
                                    Paul Gnana Sundar
                                </p>
                                <p className="text-sm let text-black">
                                    Product Support-Lead
                                </p>
                            </div>
                        </div>
                        <div class="card">
                            <img
                                alt="..."
                                className=" font-extrabold"
                                src={require("assets/img/team/PRADHABH PANDIAN.png").default} />
                            <div class="data">
                                <p className="font-bold text-base fnt text-black">
                                    Pradhabh Pandian
                                </p>
                                <p className=" text-sm let text-black">
                                    QA Engineer
                                </p>
                            </div>
                        </div>
                        <div class="card pl-1">
                            <img
                                alt="..."
                                className=" font-extrabold"
                                src={require("assets/img/team/rajendra.png").default} />
                            <div class="data">
                                <p className="font-bold text-base fnt text-black ">
                                    Rajendra Prasad M K
                                </p>
                                <p className=" text-sm let text-black">
                                    Senior Manager - Human Resource
                                </p>
                            </div>
                        </div>
                        <div class="card pl-1">
                            <img
                                alt="..."
                                className=" font-extrabold"
                                src={require("assets/img/team/Rahul.png").default} />
                            <div class="data">
                                <p className="font-bold text-base fnt text-black ">
                                    Rahul Sharma
                                </p>
                                <p className=" text-sm let text-black">
                                    Area Sales Manager
                                </p>
                            </div>
                        </div>
                        
                        
                    </div>
                </div>
            </section>

            {/* 3rd Row */}

            <section className="md:block hidden">
                <div className="w-full lg:w-12/12 md:w-12/12 ">
                    <div className="flex flex-wrap justify-center">
                    <div class="card">
                            <img
                                alt="..."
                                className=" font-extrabold"
                                src={require("assets/img/team/SAKET CHIRANIA.png").default} />
                            <div class="data">
                                <p className="font-bold fnt text-base text-black">
                                    Saket Chirania
                                </p>
                                <p className="text-sm let text-black">
                                    Co-Founder
                                </p>
                            </div>
                        </div>
                    <div class="card">
                            <img
                                alt="..."
                                className=" font-extrabold"
                                src={require("assets/img/team/RAM.png").default} />
                            <div class="data">
                                <p className="font-bold text-base fnt text-black ">
                                    Ram Manohar
                                </p>
                                <p className="let text-sm text-black">
                                    Area Sales Manager
                                </p>
                            </div>
                        </div>
                        <div class="card ">
                            <img
                                alt="..."
                                className=" font-extrabold"
                                src={require("assets/img/team/SABYASACHI.png").default} />
                            <div class="data">
                                <p className="font-bold text-base fnt text-black">
                                    Sabyasachi Satpathy
                                </p> 
                                <p className=" text-sm let text-black">
                                    Strategic Partner
                                </p>
                            </div>
                        </div>
                        <div class="card">
                            <img
                                alt="..."
                                className=" font-extrabold"
                                src={require("assets/img/team/sameer.png").default} />
                            <div class="data">
                                <p className="font-bold fnt text-base text-black">
                                    Sameer Singhai
                                </p>
                                <p className="text-sm let text-black">
                                    Business Head - Fintech
                                </p>
                            </div>
                        </div>

                    

                        <div class="card">
                            <img
                                alt="..."
                                className=" font-extrabold"
                                src={require("assets/img/team/TIMMAPURAM HEMANTH KUMAR.png").default} />
                            <div class="data">
                                <p className="font-bold text-base fnt text-black">
                                    Timmapuram Hemanth Kumar
                                </p>
                                <p className=" text-sm let text-black">
                                    Software Development Engineer
                                </p>
                            </div>
                        </div>

                        <div class="card pl-1">
                            <img
                                alt="..."
                                className=" font-extrabold"
                                src={require("assets/img/team/UDHAY.png").default} />
                            <div class="data">
                                <p className="font-bold fnt text-base text-black">
                                    Udhay Kumar C
                                </p>
                                <p className="text-sm let text-black">
                                    Associate Director - Products
                                </p>
                            </div>
                        </div>

                        <div class="card">
                            <img
                                alt="..."
                                className=" font-extrabold"
                                src={require("assets/img/team/UDAYAKUMAR.png").default} />
                            <div class="data">
                                <p className="font-bold text-base fnt text-black">
                                    Udayakumar Chinnappan
                                </p>
                                <p className="text-sm let text-black">
                                    Lead Engineer
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* 4th Row */}

            <section className="md:pb-20 md:block hidden">
                <div className="w-full lg:w-12/12 md:w-12/12 ">
                    <div className="flex flex-wrap justify-center">
                    <div class="card">
                            <img
                                alt="..."
                                className=" font-extrabold"
                                src={require("assets/img/team/neha.png").default} />
                            <div class="data">
                                <p className="font-bold fnt text-base text-black">
                                    Neha Gupta
                                </p>
                                <p className="text-sm let text-black">
                                    Key Account Manager
                                </p>
                            </div>
                        </div>
                    <div class="card">
                            <img
                                alt="..."
                                className=" font-extrabold"
                                src={require("assets/img/team/satish.png").default} />
                            <div class="data">
                                <p className="font-bold text-base fnt text-black ">
                                    Satish G
                                </p>
                                <p className="let text-sm text-black">
                                    Manager - Operations
                                </p>
                            </div>
                        </div>
                        <div class="card ">
                            <img
                                alt="..."
                                className=" font-extrabold"
                                src={require("assets/img/team/rajesh_chandela.png").default} />
                            <div class="data">
                                <p className="font-bold text-base fnt text-black">
                                    Rajesh Chandela
                                </p>
                                <p className=" text-sm let text-black">
                                    Category Head- Dry Fruits and Nuts
                                </p>
                            </div>
                        </div>
                        <div class="card">
                            <img
                                alt="..."
                                className=" font-extrabold"
                                src={require("assets/img/team/chirag.png").default} />
                            <div class="data">
                                <p className="font-bold fnt text-base text-black">
                                    Chirag Sanghani
                                </p>
                                <p className="text-sm let text-black">
                                    Manager - Credit & Treasury
                                </p>
                            </div>
                        </div>

                    

                        <div class="card">
                            <img
                                alt="..."
                                className=" font-extrabold"
                                src={require("assets/img/team/karthika.png").default} />
                            <div class="data">
                                <p className="font-bold text-base fnt text-black">
                                    Karthika RV
                                </p>
                                <p className=" text-sm let text-black">
                                    QA Engineer
                                </p>
                            </div>
                        </div>

                        <div class="card">
                            <img
                                alt="..."
                                className=" font-extrabold"
                                src={require("assets/img/team/VICKY DODANI.png").default} />
                            <div class="data">
                                <p className="font-bold text-base fnt text-black ">
                                    Vicky Dodani
                                </p>
                                <p className="text-sm let text-black">
                                    Co-Founder
                                </p>
                            </div>
                        </div>

                        <div class="card">
                            <h1 className="font-bold text-4xl fnt text-black  text-center items-center pt-12">
                                Plus 50 More
                            </h1>
                        </div>
                    </div>
                </div>
            </section>

            


            {/* Mobile View */}
            <section className="md:pt-32 md:hidden block pt-12">
                <div className="w-full lg:w-12/12 md:w-12/12 ">
                    <div className="flex flex-wrap justify-center">

                       <div class="card">
                            <img
                                alt="..."
                                className=" font-extrabold"
                                src={require("assets/img/team/ABHINAV.png").default} />
                            <div class="data">
                                <p className="font-bold text-base fnt text-black">
                                    Abhinav Bhardwaj
                                </p>
                                <p className="text-sm let text-black">
                                    Regional Sales Head
                                </p>
                            </div>
                        </div>
                        <div class="card ">
                            <img
                                alt="..."
                                className=" font-extrabold"
                                src={require("assets/img/team/ABHISHEK.png").default} />
                            <div class="data">
                                <p className="font-bold text-base fnt text-black">
                                    Abhishek Shrivastava
                                </p>
                                <p className="text-sm let text-black">
                                    Team Lead - Sales
                                </p>
                            </div>
                        </div>
                        <div class="card ">
                            <img
                                alt="..."
                                className=" font-extrabold"
                                src={require("assets/img/team/aman_kumar.png").default} />
                            <div class="data">
                                <p className="font-bold text-base fnt text-black">
                                    Aman Kumar
                                </p>
                                <p className="text-sm let text-black">
                                    Partner - Strategy And Business
                                </p>
                            </div>
                        </div>
                        <div class="card ">
                            <img
                                alt="..."
                                className=" font-extrabold"
                                src={require("assets/img/team/abhishek_saraswat.png").default} />
                            <div class="data">
                                <p className="font-bold text-base fnt text-black">
                                    Abhishek Saraswat
                                </p>
                                <p className="text-sm let text-black">
                                Senior Manager - Finance
                                </p>
                            </div>
                        </div>

                        <div class="card ">
                            <img
                                alt="..."
                                className=" font-extrabold"
                                src={require("assets/img/team/Chandrika.jpg").default} />
                            <div class="data">
                                <p className="font-bold text-base fnt text-black ">
                                    Chandrika Kathayat
                                </p>
                                <p className="let text-sm text-black">
                                    Business Partner - HR
                                </p>
                            </div>
                        </div>
                        <div class="card ">
                            <img
                                alt="..."
                                className=" font-extrabold"
                                src={require("assets/img/team/karthi.png").default} />
                            <div class="data">
                                <p className="font-bold text-base fnt text-black ">
                                    Karthik Sundararaman
                                </p>
                                <p className="let text-sm text-black">
                                    Head of Growth and Strategy
                                </p>
                            </div>
                        </div>

                        <div class="card">
                            <img
                                alt="..."
                                className=" font-extrabold"
                                src={require("assets/img/team/MARKISH ARUN.png").default} />
                            <div class="data">
                                <p className="font-bold fnt text-base  text-black">
                                    Markish Arun
                                </p>
                                <p className=" text-sm let text-black">
                                    Head of Engineering and Products
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            {/* 2nd Row */}
            <section className="md:hidden block">
                <div className="w-full lg:w-12/12 md:w-12/12">
                    <div className="flex flex-wrap justify-center">
                    <div class="card">
                                <img
                                    alt="..."
                                    className=" font-extrabold"
                                    src={require("assets/img/team/MAHENDERAN.png").default} />
                                <div class="data">
                                    <p className="font-bold text-base fnt text-black">
                                        Mahendran G
                                    </p>
                                    <p className="text-sm let text-black">
                                        Software Development Engineer
                                    </p>
                                </div>
                            </div>
                        <div class="card">
                            <img
                                alt="..."
                                className=" font-extrabold"
                                src={require("assets/img/team/mayank.png").default} />
                            <div class="data">
                                <p className="font-bold fnt text-base  text-black">
                                    Mayank Shukla
                                </p>
                                <p className=" text-sm let text-black">
                                    Key Account Manager
                                </p>
                            </div>
                        </div>
                        <div class="card">
                            <img
                                alt="..."
                                className=" font-extrabold"
                                src={require("assets/img/team/mansi.png").default} />
                            <div class="data">
                                <p className="font-bold text-base fnt text-black">
                                    Manasi Das
                                </p>
                                <p className="text-sm let text-black">
                                    Communication Lead
                                </p>
                            </div>
                        </div>

                        <div class="card">
                            <img
                                alt="..."
                                className=" font-extrabold"
                                src={require("assets/img/team/PAUL.png").default} />
                            <div class="data">
                                <p className="font-bold text-base fnt text-black">
                                    Paul Gnana Sundar
                                </p>
                                <p className="text-sm let text-black">
                                    Product Support-Lead
                                </p>
                            </div>
                        </div>
                        <div class="card">
                            <img
                                alt="..."
                                className=" font-extrabold"
                                src={require("assets/img/team/PRADHABH PANDIAN.png").default} />
                            <div class="data">
                                <p className="font-bold text-base fnt text-black">
                                    Pradhabh Pandian
                                </p>
                                <p className=" text-sm let text-black">
                                    QA Engineer
                                </p>
                            </div>
                        </div>
                        <div class="card pl-1">
                            <img
                                alt="..."
                                className=" font-extrabold"
                                src={require("assets/img/team/rajendra.png").default} />
                            <div class="data">
                                <p className="font-bold text-base fnt text-black ">
                                    Rajendra Prasad M K
                                </p>
                                <p className=" text-sm let text-black">
                                    Senior Manager - Human Resource
                                </p>
                            </div>
                        </div>
                        <div class="card pl-1">
                            <img
                                alt="..."
                                className=" font-extrabold"
                                src={require("assets/img/team/Rahul.png").default} />
                            <div class="data">
                                <p className="font-bold text-base fnt text-black ">
                                    Rahul Sharma
                                </p>
                                <p className=" text-sm let text-black">
                                    Area Sales Manager
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* 3rd Row */}
            <section className="md:hidden block">
                <div className="w-full lg:w-12/12 md:w-12/12 ">
                    <div className="flex flex-wrap justify-center">
                    <div class="card">
                            <img
                                alt="..."
                                className=" font-extrabold"
                                src={require("assets/img/team/SAKET CHIRANIA.png").default} />
                            <div class="data">
                                <p className="font-bold fnt text-base text-black">
                                    Saket Chirania
                                </p>
                                <p className="text-sm let text-black">
                                    Co-Founder
                                </p>
                            </div>
                        </div>
                    <div class="card">
                            <img
                                alt="..."
                                className=" font-extrabold"
                                src={require("assets/img/team/RAM.png").default} />
                            <div class="data">
                                <p className="font-bold text-base fnt text-black ">
                                    Ram Manohar
                                </p>
                                <p className="let text-sm text-black">
                                    Area Sales Manager
                                </p>
                            </div>
                        </div>
                        <div class="card ">
                            <img
                                alt="..."
                                className=" font-extrabold"
                                src={require("assets/img/team/SABYASACHI.png").default} />
                            <div class="data">
                                <p className="font-bold text-base fnt text-black">
                                    Sabyasachi Satpathy
                                </p>
                                <p className=" text-sm let text-black">
                                    Strategic Partner
                                </p>
                            </div>
                        </div>
                        <div class="card">
                            <img
                                alt="..."
                                className=" font-extrabold"
                                src={require("assets/img/team/sameer.png").default} />
                            <div class="data">
                                <p className="font-bold fnt text-base text-black">
                                    Sameer Singhai
                                </p>
                                <p className="text-sm let text-black">
                                    Business Head - Fintech
                                </p>
                            </div>
                        </div>

                    

                        <div class="card">
                            <img
                                alt="..."
                                className=" font-extrabold"
                                src={require("assets/img/team/TIMMAPURAM HEMANTH KUMAR.png").default} />
                            <div class="data">
                                <p className="font-bold text-base fnt text-black">
                                    Timmapuram Hemanth Kumar
                                </p>
                                <p className=" text-sm let text-black">
                                    Software Development Engineer
                                </p>
                            </div>
                        </div>

                        <div class="card pl-1">
                            <img
                                alt="..."
                                className=" font-extrabold"
                                src={require("assets/img/team/UDHAY.png").default} />
                            <div class="data">
                                <p className="font-bold fnt text-base text-black">
                                    Udhay Kumar C
                                </p>
                                <p className="text-sm let text-black">
                                    Associate Director - Products
                                </p>
                            </div>
                        </div>

                        <div class="card">
                            <img
                                alt="..."
                                className=" font-extrabold"
                                src={require("assets/img/team/UDAYAKUMAR.png").default} />
                            <div class="data">
                                <p className="font-bold text-base fnt text-black">
                                    Udayakumar Chinnappan
                                </p>
                                <p className="text-sm let text-black">
                                    Lead Engineer
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            {/* 4th Row */}

            <section className="md:hidden block">
                <div className="w-full lg:w-12/12 md:w-12/12 ">
                    <div className="flex flex-wrap justify-center">
                    <div class="card">
                            <img
                                alt="..."
                                className=" font-extrabold"
                                src={require("assets/img/team/neha.png").default} />
                            <div class="data">
                                <p className="font-bold fnt text-base text-black">
                                    Neha Gupta
                                </p>
                                <p className="text-sm let text-black">
                                    Key Account Manager
                                </p>
                            </div>
                        </div>
                    <div class="card">
                            <img
                                alt="..."
                                className=" font-extrabold"
                                src={require("assets/img/team/satish.png").default} />
                            <div class="data">
                                <p className="font-bold text-base fnt text-black ">
                                    Satish G
                                </p>
                                <p className="let text-sm text-black">
                                    Manager - Operations
                                </p>
                            </div>
                        </div>
                        <div class="card ">
                            <img
                                alt="..."
                                className=" font-extrabold"
                                src={require("assets/img/team/rajesh_chandela.png").default} />
                            <div class="data">
                                <p className="font-bold text-base fnt text-black">
                                    Rajesh Chandela
                                </p>
                                <p className=" text-sm let text-black">
                                    Category Head- Dry Fruits and Nuts
                                </p>
                            </div>
                        </div>
                        <div class="card">
                            <img
                                alt="..."
                                className=" font-extrabold"
                                src={require("assets/img/team/chirag.png").default} />
                            <div class="data">
                                <p className="font-bold fnt text-base text-black">
                                    Chirag Sanghani
                                </p>
                                <p className="text-sm let text-black">
                                    Manager - Credit & Treasury
                                </p>
                            </div>
                        </div>

                    

                        <div class="card">
                            <img
                                alt="..."
                                className=" font-extrabold"
                                src={require("assets/img/team/karthika.png").default} />
                            <div class="data">
                                <p className="font-bold text-base fnt text-black">
                                    Karthika RV
                                </p>
                                <p className=" text-sm let text-black">
                                    QA Engineer
                                </p>
                            </div>
                        </div>

                        <div class="card">
                            <img
                                alt="..."
                                className=" font-extrabold"
                                src={require("assets/img/team/VICKY DODANI.png").default} />
                            <div class="data">
                                <p className="font-bold text-base fnt text-black ">
                                    Vicky Dodani
                                </p>
                                <p className="text-sm let text-black">
                                    Co-Founder
                                </p>
                            </div>
                        </div>

                        <div class="card">
                            <h1 className="font-bold text-4xl fnt text-black  text-center items-center pt-12">
                                Plus 50 More
                            </h1>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
}


