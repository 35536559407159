import React, { Component } from "react";
import emailjs from "emailjs-com";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";
import { Link } from "react-router-dom";




export default class Forms extends

    Component {
    constructor(props) {
        super(props)
        this.state = {
            name: '',
            phonenumber: '',
            email: '',
            message: '',

        }
    }

    handleFullName = e => {
        this.setState({
            name: e.target.value
        })
    }

    handleEmail = e => {
        this.setState({
            email: e.target.value
        })
    }

    handlePhoneNumber = e => {
        this.setState({
            phonenumber: e.target.value
        })
    }

    handleMessage = e => {
        this.setState({
            message: e.target.value
        })
    }

    handleSubmit = e => {
        e.preventDefault()
        console.log(this.state)

    }

    resetForm = () => {
        this.setState({
            name: '',
            phonenumber: '',
            email: '',
            message: '',
        })
    }



    render() {
        return (
            <>
                <div className="form">
                    <h1 >
                        Contact Us
                    </h1>
                    <form onSubmit={this.handleSubmit}>
                        <div className="text_field">
                            <input type="text" required onChange={this.handleFullName} value={this.state.name} />

                            <span></span>
                            <label>Full Name</label>
                        </div>
                        <div className="text_field">
                            <input type="number" required onChange={this.handlePhoneNumber} value={this.state.phonenumber} />

                            <span></span>
                            <label>Phone Number</label>
                        </div>
                        <div className="text_field">
                            <input type="email" required onChange={this.handleEmail} value={this.state.email} />

                            <span></span>
                            <label>Email</label>
                        </div>

                        <div className="text_field">
                            <textarea type="text" required onChange={this.handleMessage} value={this.state.message} />

                            <span></span>
                            <label>Message</label>
                        </div>

                        <div className="button">
                            <button type="submit">

                                SEND</button>
                        </div>
                    </form>
                </div>
            </>
        )
    }
}