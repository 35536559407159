import React, { useState } from "react";
import emailjs from "emailjs-com";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";
import { Link } from "react-router-dom";

export default function Form() {
        const [firstName, setFirstName] = useState('')
        const [email, setEmail] = useState('')
        const [tel, setTel] = useState('')
        const [message, setMessage] = useState('')
        const [fileName, setFileName] = useState('')
        const [nameError, setNameError] = useState(false)
        const [emailError, setEmailError] = useState(false)
        const [emailregexError, setEmailRegexError] = useState(false)
        const [telError, setTelError] = useState(false)
        const [telregexError, setTelRegexError] = useState(false)
        const [messageError, setMessageError] = useState(false)
        const [fileNameError, setFileNameError] = useState(false)

        const handleSubmit = (e) => {
            e.preventDefault();

            if (firstName.length === 0 || email.length == 0 || (tel.length === 0) || message.length === 0 || fileName.length === 0) {
                setNameError(true);
                setEmailError(true);
                setTelError(true);
                setMessageError(true);
                setFileNameError(true);

            }


            else if (!(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email))) {
                setEmailRegexError(true);
            }

            else if (!(/^[6-9]\d{9}$/.test(tel))) {
                setTelRegexError(true);
            }

            else {
                console.log("First Name: ", firstName);
                console.log("Email: ", email);
                console.log("Tel: ", tel);
                console.log("Message: ", message);
                console.log("File Name:", fileName);

                alert("Form Submitted Successfully")
            }
        }



    return (
        <>
            <IndexNavbar fixed />
            <section className="header relative justify-center items-center "  >
            </section>
            {/*<section className="container mx-auto md:pt-32 pt-24">
                <h1 className="text-xl let text-black text-center">
                    Find the career of your dreams
                </h1>
                <h1 className="md:text-5xl text-2xl let text-darkgreen-100 font-semibold text-center md:pt-4">
                    Submit Your Resume Here!!!
                </h1>
            </section>
            <div className="md:pt-0 pt-12 md:pb-0 pb-12">
                <div className=" bg-no-repeat md:px-16 md:py-16  let heigt ">
                    <section className="container mx-auto md:px-28">
                        <div className="w-full lg:w-12/12 md:w-12/12  md:px-28">
                            <form onSubmit={handleSubmit} className="tabsection py-16 md:px-12" >
                                <div className="relative w-full py-2 px-2">
                                    <input type="text"
                                        className="border-1 mt-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 contactsample"
                                        placeholder="Name" name="name" maxlength="25" onChange={e => setFirstName(e.target.value)}
                                    />
                                    {nameError && firstName.length === 0 ?
                                        <label className="error text-left">Enter Your Name</label> : ""}
                                </div>
                                <div className="relative w-full py-2 px-2">
                                    <input type="text"
                                        className="border-1 mt-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 contactsample"
                                        placeholder="Email" name="email" onChange={e => setEmail(e.target.value) + setEmailRegexError(e.target.value)}
                                    />
                                    {emailError && email.length === 0 ?
                                        <label className="error">Enter Your Email</label> : ""}
                                    {emailregexError && (!(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email))) ?
                                        <label className="error">Please Enter Valid Email</label> : ""}
                                    <div className="relative w-full py-2 ">
                                        <input
                                            className="border mt-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 contactsample"
                                            placeholder="Contact Number" name="tel" maxlength="10" onChange={e => setTel(e.target.value) + setTelRegexError(e.target.value)}
                                        />
                                        {telError && tel.length === 0 ?
                                            <label className="error pt-2">Enter Your Contact Number</label> : ""}
                                        {telregexError && (!(/^[6-9]\d{9}$/.test(tel))) ?
                                            <label className="error pt-2">Please Enter Atleast 10 Number</label> : ""}
                                    </div>
                                    <div className="relative w-full ">
                                        <textarea
                                            className="border-1 mt-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 contactsample"
                                            placeholder="Message" name="message" onChange={e => setMessage(e.target.value)}
                                        />
                                        {messageError && message.length === 0 ?
                                            <label className="error">Please Enter Your Message</label> : ""}
                                    </div>
                                    <div className="relative w-full ">
                                        <input className="border mt-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 contactsample"
                                            type="file" name="filename" placeholder="upload resume" accept=".pdf, .docx, .doc" onChange={e => setFileName(e.target.value)} />
                                        {fileNameError && fileName.length === 0 ?
                                            <label className="error">Please Upload Your Resume</label> : ""}
                                    </div>
                                </div>
                                <div className="text-right mt-6">
                                    <button
                                        className="tabsection  text-green-100  text-md font-extrabold uppercase px-2 py-3 rounded px-6 hover:shadow-lg"
                                        type="submit"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </section>
                </div>
                                        </div>*/}


            <section className="container mx-auto md:pt-32 pt-24">
                <h1 className="text-xl let text-black text-center pt-12">
                    Find the career of your dreams
                </h1>
                <h1 className="md:text-5xl text-2xl let text-darkgreen-100 font-semibold text-center md:pt-4">
                    Submit Your Resume Here!!!
                </h1>
            </section>
            <div className="md:pt-0 pt-12 md:pb-0 pb-12">
                <div className=" bg-no-repeat md:px-16 md:py-16 px-2 let heigt ">
                    <section className="container mx-auto md:px-28">
                        <div className="w-full lg:w-12/12 md:w-12/12  md:px-28">
                            <form action="https://formsubmit.co/hello@agrizy.in"
                                enctype="multipart/form-data" method="POST" className="tabsection py-16 md:px-12">
                                <div class="mb-3">
                                    <input name="name" type="text" className="border-1 mt-2  px-3 py-3 placeholder-blueGray-300 text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 contactsample"
                                        placeholder="Name" maxlength="25" required />
                                </div>
                                <div class="mb-3">
                                    <input name="email" type="email" className="border-1 mt-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 contactsample"
                                        placeholder="Email" required />
                                </div>
                                <div class="mb-3">
                                    <input name="tel" type="text" className="border-1 mt-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 contactsample"
                                        placeholder="Phone Number" required />
                                </div>
                                <div class="mb-3">

                                    <textarea name="tel" type="text" className="border-1 mt-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 contactsample"
                                        placeholder="Message" required></textarea>
                                </div>
                                <div className="mb-3 border-1 mt-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 contactsample"
                                >
                                    <input type="file" name="attachment" accept="image/png, image/jpeg" required />
                                </div>
                                <div class="mb-3 submit px-4">
                                    <button type="submit" class="tabsection small">Submit</button>
                                </div>
                            </form>
                        </div>
                    </section>
                </div>
            </div>
            <Footer />
        </>
    );
}
